import React, {useState} from 'react';
import {Form} from "react-bootstrap";

function Reset(props) {
    let main=props.main
    let domains=props.domains
    const [email,setEmail]=useState(null)
    const [message,setMessage]=useState(null)
    const [type,setType]=useState(1)
    const [loadingSubmit,setLoadingSubmit]=useState(false)
    const domain=props.domain
    const csfrtoken=props.csfrtoken
    const validateEmail = (mail) =>{
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
        {
            return (true)
        }
        return (false)
    }

    const submitRequest = () =>{
        if(email === null) {
            setMessage(<div className={'alert alert-warning'}>El campo de correo no puede estar vacío.</div>)
        }else{
            let validate=validateEmail(email)
            if(!validate){
                setMessage(<div className={'alert alert-warning'}>Este correo no es válido.</div>)
            }else{
               /* setLoadingSubmit(true)*/
                let formData = new FormData()
                formData.append('email',email)
                formData.append('type',type)
                formData.append("_csrfToken",csfrtoken)
                let header = new Headers({
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type': 'multipart/form-data'
                });

                let sentData={
                    method:'POST',
                    mode: 'cors',
                    header: header,
                    body:formData
                };

                let url=props.domainApi+'resetPass/'+props.apicode
                fetch(url,sentData).then(function (response) {
                    return response.text()
                }).then((jsonvalue)=> {
                    console.log(jsonvalue)
                    /*jsonvalue=JSON.parse(jsonvalue);
                    if(jsonvalue.error === 0){
                        setMessage(<div className={'alert alert-success'}>Un correo con la nueva contraseña ha sido enviado.</div>)
                    }else{
                        setMessage(<div className={'alert alert-warning'}>Esta cuenta no existe.</div>)
                        setLoadingSubmit(false)
                    }*/

                })
            }
        }
    }
  return (
      <div className="signin-panel">
          <div className="signin-sidebar row">
              <div className="signin-sidebar-body col-12 col-md-6 p-5">
                  <h4 className="signin-title mt-5">{main.traducciones.olvido_contrasena}</h4>
                  <h5 className="signin-subtitle">{main.traducciones.llene_los_campos_con_los_datos_de_su_cuenta_para_restablecerla}</h5>

                  <div className="signin-form">
                      <div>{message}</div>
                      <div className="form-group mt-3">
                          <label>Email</label>
                          <input type="email" onChange={(e)=>setEmail(e.target.value)} className="form-control mb-5 form-control-lg"
                                  />
                      </div>
                      <div className="form-group">
                          <button onClick={()=>submitRequest()}
                                  disabled={loadingSubmit ? true : false}
                                  className="btn btn-brand-01 btn-block btn-lg btn-secondary btn-uppercase">{loadingSubmit ? "..." : <span>{main.traducciones.restablecer}</span>}</button>
                          <br/>
                          <a href="/login" className="btn btn-block btn-light btn-lg btn-uppercase mb-5">{main.traducciones.volver}</a>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-md-6 py-0">
                  <div className={'bgimgvertical'} style={{backgroundPosition:"center",backgroundImage:'url('+domains.domain+'/'+main.result.img+')',height:'100%'}}></div>
              </div>
          </div>
      </div>
  )
}

export default Reset;
