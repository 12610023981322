import React, {useState} from 'react';
import 'moment-timezone';
import * as Bootstrap from "react-bootstrap"

function Appointment (props){
    let domains=props.domains
    const [appointment,setAppointment]=useState(null)
    let orderid=props.match.params.id

    const loadContent = () => {
            let params = {orderid:orderid, langid:domains.language}
            params=btoa(JSON.stringify(params))

            let url=domains.domainapi+'createAppointmentData/'+domains.apicode+'/'+params
            console.log(url)

            fetch(url).then(function (response) {
                return response.json()
            }).then((jsonvalue)=>{
                setAppointment(jsonvalue)
            })
    }

    useState(()=>loadContent(),['appointment'])


    return <div className={'appointment'}>
            <Bootstrap.Container fluid={true}>

            </Bootstrap.Container>
        </div>
}
export default Appointment;
