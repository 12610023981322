import React, {useState} from "react";
import {Loader,Title,Subtitle,Moneyformat} from './General'
import * as Bootstrap from "react-bootstrap"
import {Helmet} from "react-helmet"

function Article(props) {
    const id=props.match.params.id
    let {domains,main} = props
    const [content,setContent]=useState(false)
    const [contentModal,setContentModal]=useState(null)
    const [lgShow, setLgShow] = useState(false);
    const fetchHome = () => {
        let objectotosend={'langid':domains.language,'itemid':id}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getArticledetail/'+domains.apicode+'/'+objectotosend

        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd)
                }else{
                    //setTickets([])
                }
            })
    }

    const openModal = (item) => {
        setContentModal(item)
        setLgShow(true)
    }

    if(!content){
        fetchHome()
        return <Loader/>
    }else{
        return <div className='home'>
                {content ? <div className={'container'}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{content.result.content.titletranslation} - {main.result.name}</title>
                        <meta name="description" content={content.result.content.shortdescriptiontranslation} />
                    </Helmet>
                    <div className={'row justify-content-center'}>
                        <div className={'col col-md-8 col-lg-7'}>
                            <div className={'py-0 my-5 '}>
                                <a href={'/'+content.result.blog.aliastranslations} className={'color3 py-3 m-0 d-inline-block'}>{main.traducciones.volver}</a>
                                <div className={'panoimg mb-5'} style={{paddingBottom:'70%', backgroundImage:'url('+domains.domain+'/'+content.result.content.img+')'}}>

                                </div>
                                <div className={'text-center'}>
                                <Title color={'color2'} value={content.result.content.titletranslation}/>
                                </div>
                                <div className={'row justify-content-center'}>
                                    <div className={'col-12 col-md-12 col-lg-12 mt-3 color2'}>
                                        <div className={'text-justify'} dangerouslySetInnerHTML={{__html:content.result.content.shortdescriptiontranslation}}></div>
                                        <hr className={'my-5'}/>
                                        <div className={'text-justify'} dangerouslySetInnerHTML={{__html:content.result.content.descriptiontranslation}}></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div> : <div className={'py-5 text-center'}><Loader/></div>}
            </div>
    }

/*
* {content.result.services !== undefined && content.result.services !== null ?
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'text-center mb-5'}>
                            <Title color={'color2'} value={content.translations.servicios_productos_relacionados}/>
                            </div>
                            <Bootstrap.Row className={'justify-content-center'}>
                                {content.result.services.map(ser=>{
                                        return (
                                            <Bootstrap.Col className={'mb-5 color2'} xs={12} md={4}>
                                                <div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+ser.img+')'}}></div>
                                                <Subtitle value={ser.titletranslation}></Subtitle>
                                                <div className={'font-italic'}><Moneyformat value={ser.price}></Moneyformat></div>
                                                <div className={'my-3 text-justify'}>{ser.shortdescriptiontranslation}</div>
                                                <a href={'/s/'+ser.aliastranslation+'/'+ser.id} className={'btn rounded-0 py-3 mt-4 btn-block bordercolor2 color2'}>{content.translations.leer_mas}</a>
                                            </Bootstrap.Col>
                                        )
                                    }

                                )}

                            </Bootstrap.Row>
                        </div>
                    </div>
                     : null}*/
}

export default Article;
