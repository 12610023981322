import React, {useState} from "react";
import {Loader, Title, ArticleBlock, Testimonialitem} from './General'
import {Container, Row, Col,Carousel, Modal} from "react-bootstrap"
import {Helmet} from "react-helmet"


function Slideshow(props){
    const slideshow=props.content
    const {domains}=props
    return <Carousel indicators={false} controls={false}>
                <Carousel.Item>
                <video
                    poster={domains.domain+'/bg-cell200.jpg'}
                    playsInline
                    autoPlay loop muted style={{objectFit:"cover",width:"100%", height: '100vh'}}>
                    <source src={domains.domain+'/bgadelfahome.mp4'} type="video/mp4" />
                    <source src={domains.domain+'/bgadelfahome.webm'} type="video/webm" />
                </video>
                </Carousel.Item>
                {slideshow.map((slide,index)=>
                    <Carousel.Item key={'slideshow'+index}>
                        <div className={'w-100 slideimage'} style={{ height:'100vh', backgroundImage:'url('+domains.domain+'/'+slide.img+')'}}></div>
                    </Carousel.Item>
                )}
            </Carousel>
}

const ArticulosYCursos = (props) => {
    let {content,domains,main} = props

    return <div id={'bloghome'} className={'bgcolor1 py-5 color4'}>
        <Container className={'py-5'}>
            <Row className={'justify-content-between align-items-center'}>
                <Col>
                    <Title value={content.articulos_categories.titletranslations}></Title>
                    <p className={'mb-5'}>{content.articulos_categories.descriptiontranslations}</p>
                </Col>
                <Col className={'text-right'}>
                    <a href={'/'+content.articulos_categories.aliastranslations} className={'color4'}>{content.traducciones.vertodos}</a>
                </Col>
            </Row>
            <Row>
                {content.articulos.map(item=>{
                    return <Col key={'art'+item.id} lg={4} md={4} sm={12}>
                        <ArticleBlock btnclasscolor={'bordercolor4 color4'} producto={item} main={main} content={content} domains={domains} />
                    </Col>})}
            </Row>
            </Container>
    </div>
}

function Servicescategories(props) {
    const {content,domains,main}=props
    let valtosize = 4
    let classval = "square"
    if(!main.result.activar_cursos){
        valtosize=4
        classval = "square"
    }
    return <div className={'bgcolor2 py-5'} {...props}>
            <div className={'py-5 color3'}>
                <Container>
                    <Row className={'justify-content-start'}>
                        <Col xs={12} md={valtosize}>
                            <Row>
                                <Col>
                                    <div>
                                        <a href={'/'+content.servicecontent.aliastranslations} className={'link'} >
                                            <div className={'w-100 squareimage '+classval} style={{backgroundImage:'url('+domains.domain+'/'+content.servicecontent.img+')'}}></div>
                                        </a>
                                        <Title className={'mt-4'} value={content.servicecontent.titletranslations}></Title>
                                        <p className={'mb-3 mt-4'}>{content.servicecontent.descriptiontranslations}</p>
                                        <a href={'/'+content.servicecontent.aliastranslations} className={'btn rounded-0 py-3 mt-3 btn-block color3 bordercolor3'}>{content.traducciones.vertodos}</a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={valtosize}>
                            <div>
                                <a href={'/'+content.productscontent.aliastranslations} className={'link'} >
                                    <div className={'w-100 squareimage '+classval} style={{backgroundImage:'url('+domains.domain+'/'+content.productscontent.img+')'}}></div>
                                </a>
                                <Title className={'mt-4'} value={content.productscontent.titletranslations}></Title>
                                <p className={'mb-3 mt-4'}>{content.productscontent.descriptiontranslations}</p>
                                <a href={'/'+content.productscontent.aliastranslations} className={'btn rounded-0 py-3 mt-3 btn-block color3 bordercolor3'}>{content.traducciones.vertodos}</a>
                            </div>
                        </Col>
                        {main.result.activar_cursos ? <Col xs={12} md={4} >
                            <Row>
                                <Col>
                                    <div>
                                        <a href={'/'+content.cursos.aliastranslations} className={'link'} >
                                            <div className={'w-100 squareimage square'} style={{backgroundImage:'url('+domains.domain+'/'+content.cursos.img+')'}}></div>
                                        </a>
                                        <Title className={'mt-4'} value={content.cursos.titletranslations}></Title>
                                        <p className={'mb-3 mt-4'}>{content.cursos.descriptiontranslations}</p>
                                        <a href={'/'+content.cursos.aliastranslations} className={'btn rounded-0 py-3 mt-3 btn-block color3 bordercolor3'}>{content.traducciones.vertodos}</a>
                                    </div>
                                </Col>
                            </Row>
                        </Col> : null }
                    </Row>
                </Container>
            </div>
        </div>
}
/*
const Products = (props) => {
    let {content, domains, setProduct, addToCart,main} = props
    return <div id={'products-home'} className={'bg-white py-5'} {...props}>
        <Container className={'py-5 color2'}>
            <Row>
                <Col xs={12} md={8}>
                    <Title value={content.productscontent.titletranslations}></Title>
                    <p className={'mb-5'}>{content.productscontent.descriptiontranslations}</p>
                </Col>
            </Row>
            <Row>
                {content.products.map(pro=>
                    <Col lg={4} md={4} sm={12}><MosaicProduct main={main} domains={domains} item={pro} addToCart={addToCart} setProduct={setProduct} /></Col>
                )}
            </Row>
        </Container>
    </div>
}*/

const Testimonials = (props) =>{
    let {content} = props
    return <Container className={'py-5 testimonials'}>
        <Row className={'py-5 justify-content-start'}>
            <Col sm={12} lg={8}>
                <Title className={'color2'} value={content.testimonialscontent.titletranslations}></Title>
                <p className={'my-5 color2'}>{content.testimonialscontent.descriptiontranslations}</p>
                <Carousel className={'mb-5'} fade controls={false}>
                    {content.testimonials.map(item=><Carousel.Item key={'testimonials'+item.id}>
                        <Testimonialitem item={item}/>
                    </Carousel.Item>)}
                </Carousel>
            </Col>
        </Row>
    </Container>
}

const Promociones = ({data, domains}) => {
    const [modalShow, setModalShow] = useState(true)
    return data.length > 0 ? (
        <div>
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter-promociones"
            centered
            >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
            <Carousel>
                {
                data.map(item =>
                    <Carousel.Item>
                        <a
                            href={item.linktranslation !== null && item.linktranslation !== "" ? item.linktranslation : null}
                        >
                            <img
                                className="d-block w-100"
                                src={domains.domain+'/'+item.img}
                                alt={item.nametranslation}
                            />
                        </a>
                    </Carousel.Item>
                )
                }
                </Carousel>
            </Modal.Body>
        </Modal>

        </div>
        )
        :
        null
    
}

function Home(props) {
    let {domains, main} = props
    const [content,setContent]=useState(false)
    const fetchHome = () => {
        let objectotosend={'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getHome/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd)
                }else{
                    //setTickets([])
                }
            })
    }

    if(!content){
        fetchHome()
    }

    return <div className='home'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{main.result.name}</title>
                <meta name="description" content={main.result.slogantranslate} />
            </Helmet>
            {content ? <div>
                <Promociones data={main.promociones} domains={domains} />
                <Slideshow domains={domains} content={content.slideshow}/>
                <Servicescategories style={{marginTop:'-6px'}} main={main} id={'serviceshome'} domains={domains} content={content}/>
                <Testimonials domains={domains} content={content}/>
                <ArticulosYCursos main={main} domains={domains} content={content}/>
            </div> : <div className={'py-5 text-center'}><Loader/></div>}
        </div>
}

export default Home;
