import React, {useState} from 'react';
import {Title,Subtitle,Loader} from './General'
function StructureContent(props) {
    let content=props.content
    let domain=props.domain
    if(content.img == null){
        content.img='panel/img/IMG_099395.jpg'
    }
    return(
        <div>
            <div className={'mainimg h-150 bgimg'} style={{backgroundImage:"url("+domain+'/'+content.img+")"}}></div>
            <div className={'container py-5'}>
                <a className={'btn bgcolor2 mb-3 text-white'} href="javascript: history.go(-1)">Volver</a>
                <Title value={content.titletranslation} />
                <Subtitle value={content.content_category.titletranslation} />
                <div className={'mt-3'} dangerouslySetInnerHTML={{__html:content.descriptiontranslation}}></div>
            </div>
        </div>
    )
}
function Content(props) {
    const [loaded,setLoaded]=useState(false)
    const [detail,setDetail]=useState(false)
    let domains=props.domains
    const idContent = props.match.params.id
    const loadContent = () => {
        let paramstopass={idContent:idContent,langid:domains.language}
        paramstopass=btoa(JSON.stringify(paramstopass))
        let url = domains.domainapi+'contentData/'+domains.apicode+'/'+paramstopass
        fetch(url).then((response)=>{
            return response.json();
        }).then((json)=>{
            setDetail(json)
            setLoaded(true)
        })
    }
    if(loaded && detail){
        return <StructureContent domain={domains.domain} content={detail}/>
    }else{
        loadContent()
        return <Loader/>
    }
}

export default Content;
