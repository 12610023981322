import React, {useState} from "react";
import {Loader,Title,Subtitle,Moneyformat, removeHTML} from './General'
import {Button, Container, Col, Row} from "react-bootstrap"
import {Helmet} from "react-helmet"

function Service(props) {
    const id=props.match.params.id
    let {domains, main} = props
    const [content,setContent]=useState(false)

    const fetchHome = () => {
        let objectotosend={'langid':domains.language,'itemid':id}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getServicedetail/'+domains.apicode+'/'+objectotosend

        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd)
                }else{
                    //setTickets([])
                }
            })
    }

    if(!content){
        fetchHome()
        return <Loader/>
    }else{
        <Helmet>
            <title>{removeHTML(content.result.servicecontent.subtitletranslation)}</title>
            <meta name="description" content={removeHTML(content.result.servicecontent.descriptiontranslation)}/>
        </Helmet>
        return <div className='home'>
            {content ? <div>
                <div className={'panoimg'} style={{backgroundImage:'url('+domains.domain+'/'+content.result.servicecontent.img+')'}}>
                    <div className={'bgcolor1transparent w-100 h-100'}></div>
                </div>
                <div className={'py-5 my-5 text-center'}>
                    <a className={'color2 d-inline-block pb-3'} href={content.result.menu.aliastranslation}>{content.translations.volver}</a>
                    <Title color={'color2'} value={content.result.servicecontent.subtitletranslation}/>
                    <div className={'row justify-content-center'}>
                        <div className={'col-12 col-md-8 col-lg-6 mt-3 color2'}>
                            {removeHTML(content.result.servicecontent.descriptiontranslation)}
                            <div className="text-center">
                            <Button 
                                className={'mt-4 text-white'} 
                                variant="primary" 
                                href={removeHTML(main.makeappointmentlink.linktranslated)}    
                            >
                                {removeHTML(main.makeappointmentlink.nametranslated)}
                            </Button>
                            </div>
                        </div>
                        <div className={'col-12'}>
                            <Container>
                                <div className={'row justify-content-start color2'}>
                                    {content.result.servicesitems.map(item=><Col key={'itemsservices'+item.id} xs={12} md={4} className={'mt-5 text-left'}>
                                        <a href={'/s/'+content.result.servicecontent.aliastranslation+'/'+item.aliastranslation+'/'+item.id}>
                                            <div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+item.img+')'}}></div>
                                            <Subtitle value={item.titletranslation}></Subtitle>
                                        </a>
                                        <p>{removeHTML(item.subtitletranslation)} - <span className={'font-italic'}><Moneyformat value={item.price}></Moneyformat></span></p>
                                        <div className={'my-3 text-justify'}>{removeHTML(item.shortdescriptiontranslation)}</div>
                                        <a className={'color2 px-0 border-0 bg-white'} href={'/s/'+content.result.servicecontent.aliastranslation+'/'+item.aliastranslation+'/'+item.id}>{content.translations.leer_mas}</a>
                                    </Col>)}
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            </div> : <div className={'py-5 text-center'}><Loader/></div>}
        </div>
    }
}

export default Service;
