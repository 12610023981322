import React, {useState} from "react";
import {Sideprofile,Cartcontent,Loader} from './General'
import * as Bootstrap from "react-bootstrap"

function Profile(props) {
    let {domains, sessionuser,updateItem,toogleCart,removeItem,cartContent,main} = props
    const [content,setContent]=useState(false)
    if(sessionuser === null){
        window.location.href = '/login';
        return <Loader/>
    }else{
        let params={userid:sessionuser.id,langid:domains.language}
        params=btoa(JSON.stringify(params))
        let url=domains.domainapi+'getprofiledetails/'+domains.apicode+'/'+params
        if(!content){
            fetch(url).then((response)=>{
                return response.json()}
            ).then((json)=>{
                setContent(json.result)
            })
            return <Loader/>
        }else{
            return <div className='profile'>
                    <Bootstrap.Container>
                        <Bootstrap.Row>
                            <Bootstrap.Col>
                                <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                            </Bootstrap.Col>
                            <Bootstrap.Col xs={12} sm={12} md={9} lg={10}>
                                <h5 className={'mb-3'}>{content.translations.hola} <strong>{sessionuser.name} {sessionuser.lastname}</strong>!</h5>
                                <Bootstrap.CardColumns>
                                    <Bootstrap.Card className={'bgcolor2'}>
                                        <Bootstrap.Card.Body>
                                            <Bootstrap.Card.Text>
                                                <p className={'text-white'}>{content.translations.carrito}</p>
                                                <Cartcontent main={main} updateItem={updateItem} toogleCart={toogleCart} removeItem={removeItem} domains={domains} cartContent={cartContent} {...props}/>
                                            </Bootstrap.Card.Text>
                                        </Bootstrap.Card.Body>
                                    </Bootstrap.Card>
                                    <Bootstrap.Card>
                                        <Bootstrap.Card.Body className={'mx-0'}>
                                            {content ?
                                                <div>
                                                    <h5>{content.translations.ordenes}</h5>
                                                    <Bootstrap.ListGroup>
                                                        <Bootstrap.ListGroupItem className={'d-flex justify-content-between aligns-items-center'}>
                                                            Total
                                                            <span className="badge bg-primary rounded-pill text-white">{content.orders.todas}</span>
                                                        </Bootstrap.ListGroupItem>

                                                        <Bootstrap.ListGroupItem className={'d-flex justify-content-between aligns-items-center'}>
                                                            {content.translations.abiertas}
                                                            <span className="badge bg-primary rounded-pill text-white">{content.orders.abiertas}</span>
                                                        </Bootstrap.ListGroupItem>
                                                        <Bootstrap.ListGroupItem className={'d-flex justify-content-between aligns-items-center'}>
                                                            {content.translations.pagadas}
                                                            <span className="badge bg-primary rounded-pill text-white">{content.orders.pagadas}</span>
                                                        </Bootstrap.ListGroupItem>

                                                        <Bootstrap.ListGroupItem className={'d-flex justify-content-between aligns-items-center'}>
                                                            {content.translations.completadas}
                                                            <span className="badge bg-primary rounded-pill text-white">{content.orders.completadas}</span>
                                                        </Bootstrap.ListGroupItem>

                                                        <Bootstrap.ListGroupItem className={'d-flex justify-content-between aligns-items-center'}>
                                                            {content.translations.canceladas}
                                                            <span className="badge bg-primary rounded-pill text-white">{content.orders.canceladas}</span>
                                                        </Bootstrap.ListGroupItem>

                                                        <Bootstrap.ListGroupItem className={'d-flex justify-content-between aligns-items-center'}>
                                                            <Bootstrap.NavLink className={'color2 px-0'} href={'/ordenes'}>{content.translations.ver_todas}</Bootstrap.NavLink>
                                                        </Bootstrap.ListGroupItem>
                                                    </Bootstrap.ListGroup>
                                                </div>
                                                : <Loader/>}
                                        </Bootstrap.Card.Body>
                                    </Bootstrap.Card>

                                    <Bootstrap.Card>
                                        <Bootstrap.Card.Body className={'mx-0'}>
                                            <h5>{content.translations.mensajes}</h5>
                                            <div className={'alert alert-info'}>
                                                <p className={'m-0'}>{content.translations.tiene} {content.unread_messages} {content.translations.mensajes_sin_leer}</p>
                                            </div>
                                        </Bootstrap.Card.Body>
                                    </Bootstrap.Card>
                                </Bootstrap.CardColumns>
                            </Bootstrap.Col>
                        </Bootstrap.Row>
                    </Bootstrap.Container>
                </div>
        }

    }

}

export default Profile;
