import React, {useEffect, useState} from "react";
import {Container,Col, Row} from "react-bootstrap";
import {Loader, StructurePrice, Subtitle} from "./General";

function GiftCard(props) {
    let {domains,item,addToCart,setProduct,main} = props
    const [content,setContent] = useState(null)
    const [price,setPrice]=useState(0)
    const fetchGiftCard = () => {
        let objectotosend={'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getGiftCard/'+domains.apicode+'/'+objectotosend

        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd.result.product)

                }
            })
    }

    useEffect(()=>fetchGiftCard(),[])
    useEffect(()=>{
        if(content !== null ){
           setPrice(content.price)
        }
    },[content])

    return <>
            {content === null
                ?
                    <Loader/>
                :
                    <Container>
                        <Row className={'py-5 justify-content-center no-gutters itemproduct'}>
                            <Col xs={7} md={4}>
                                <div className={'w-100 squareimage square'} style={{backgroundImage:'url('+domains.domain+'/'+content.img+')'}}></div>
                            </Col>
                            <Col xs={7} md={5}>
                                <div className={'articlespacecontet pl-md-5'}>
                                    <Subtitle color={'mt-2'} value={content.titletranslation}></Subtitle>
                                    <StructurePrice item={content} setPrice={setPrice} price={price}/>
                                    <button onClick={()=>addToCart(content,1,price)} className={'btn rounded-0 py-3 mt-4 btn-block bordercolor1 color1'}>{main.traducciones.agregar_al_carrito}</button>

                                    <div className={'mt-5'} dangerouslySetInnerHTML={{__html:content.descriptiontranslation}}></div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </>

}

export default GiftCard;
