import React, {useState,useEffect} from 'react'
import * as Bootstrap from "react-bootstrap"
import * as Icons from "react-feather"
import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom"
import Home from './Views/Home'
import Detallecurso from './Views/Detallecurso'
import Content from './Views/Content'
import Articulos from './Views/Articles'
import Article from './Views/Article'
import Contact from './Views/Contact'
import Servicesdetail from './Views/Servicesdetail'
import {Loader, Cartcontent, fetching} from './Views/General'
import About from './Views/About'
import Login from './Views/Login'
import Crearcuenta from './Views/Crearcuenta'
import Profile from './Views/Profile'
import Ordenes from './Views/Ordenes'
import Ordenedetail from './Views/Ordenedetail'
import Access from './Views/Access'
import Logout from './Views/Logout'
import Reset from './Views/Reset'
import Mensajes from './Views/Mensajes'
import Editarperfil from './Views/Editarperfil'
import Checkout from './Views/Checkout'
import Paymentprocess from './Views/Paymentprocess'
import Shopdetail from './Views/Shopdetail'
import Appointment from './Views/Appointment'
import Cursos from './Views/Cursos'
import Services from './Views/Services'
import Shop from './Views/Shop'
import Cursosactivos from './Views/Cursosactivos'
import Testimonios from './Views/Testimonios'
import Responseshipping from './Views/Responseshipping'
import GiftCard from './Views/GiftCard'
import Memberships from './Views/Memberships'
import Suscripciones from './Views/Suscripciones'
import ProductDetail from './Views/ProductDetail'
import Categories from './Views/Category'


import './App.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Modal,Badge,Row,Col,Button} from "react-bootstrap"

function Contentget(props){
  let {domains,changeLang,showCart,addToCart,toggleCart,removeItem,sessionuser,product,main,handleClose}=props

  if(main !== null){
    return <BrowserRouter>
      <Switch>
        <Route exact path="/">
            <Containers main={main} {...props}>
              <Home main={main} {...props}/>
            </Containers>
        </Route>

        <Route exact path="/shipping/response">
            <Containersd main={main} {...props}>
              <Responseshipping main={main} {...props}/>
            </Containersd>
        </Route>

        <Route exact path="/reset">
            <Containersd main={main} {...props}>
              <Reset main={main} {...props}/>
            </Containersd>
        </Route>

        <Route exact path="/about-adelfa">
            <Containersd main={main} {...props}>
              <About main={main} {...props}/>
            </Containersd>
        </Route>

        <Route exact path="/suscripciones">
              <Containersd main={main} {...props}>
                  <Suscripciones main={main} {...props}/>
              </Containersd>
        </Route>

        <Route exact path="/subscriptions">
              <Containersd main={main} {...props}>
                  <Suscripciones main={main} {...props}/>
              </Containersd>
        </Route>

        <Route exact path="/memberships">
            <Containersd main={main} {...props}>
              <Memberships main={main} {...props}/>
            </Containersd>
        </Route>

        <Route exact path="/membresias">
            <Containersd main={main} {...props}>
              <Memberships main={main} {...props}/>
            </Containersd>
        </Route>

        <Route exact path="/sobre-adelfa">
          <Containersd main={main} {...props}>
            <About main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/servicios">
          <Containersd main={main} {...props}>
            <Services main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/services">
          <Containersd main={main} {...props}>
            <Services main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/login">
          <Containersd main={main} {...props}>
            <Login main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/logout">
          <Containersd main={main} {...props}>
            <Logout main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/cursos-activos">
          <Containersd main={main} {...props}>
            <Cursosactivos main={main} {...props}/>
          </Containersd>
        </Route>


        <Route exact path="/active-courses">
          <Containersd main={main} {...props}>
            <Cursosactivos main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/cursos">
          <Containersd main={main} {...props}>
            <Cursos main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/courses">
          <Containersd main={main} {...props}>
            <Cursos main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/gift-cards">
          <Containersd main={main} {...props}>
            <GiftCard main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/blog">
          <Containersd main={main} {...props}>
            <Articulos main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/articulos">
          <Containersd main={main} {...props}>
            <Articulos main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/articles">
          <Containersd main={main} {...props}>
            <Articulos main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/contacto">
          <Containersd main={main} {...props}>
            <Contact main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/contact">
          <Containersd main={main} {...props}>
            <Contact main={main} {...props}/>
          </Containersd>
        </Route>


        <Route exact path="/nueva-cuenta">
          <Containersd main={main} {...props}>
            <Crearcuenta main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/new-account">
          <Containersd main={main} {...props}>
            <Crearcuenta main={main} {...props}/>
          </Containersd>
        </Route>


        <Route exact path="/reviews">
          <Containersd main={main} {...props}>
            <Testimonios main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/access">
          <Containersd main={main} {...props}>
            <Access main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/ordenes">
          <Containersd main={main} {...props}>
            <Ordenes main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/orders">
          <Containersd main={main} {...props}>
            <Ordenes main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/productos">
          <Containersd main={main} {...props}>
            <Shop main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/products">
          <Containersd main={main} {...props}>
            <Shop main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/shop">
          <Containersd main={main} {...props}>
            <Shop main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/tienda">
          <Containersd main={main} {...props}>
            <Shop main={main} {...props}/>
          </Containersd>
        </Route>

        <Route exact path="/productos/:catname"
                 render={ propsd =>{
                     return <Containersd main={main} {...props}>
                         <Categories currency={main.currency} main={main} {...propsd} {...props}/>
                     </Containersd>
                 } }
         />

        <Route exact path="/productos/:catname/:name-:id"
                 render={ propsd =>{
                     return <Containersd main={main} {...props}>
                         <ProductDetail currency={main.currency} main={main} {...propsd} {...props}/>
                     </Containersd>
                 } }
         />

        <Route exact path="/products/:catname"
               render={ propsd =>{
                   return <Containersd main={main} {...props}>
                       <Categories currency={main.currency} main={main} {...propsd} {...props}/>
                   </Containersd>
               } }
        />

        <Route exact path="/products/:catname/:name-:id"
               render={ propsd =>{
                   return <Containersd main={main} {...props}>
                       <ProductDetail currency={main.currency} main={main} {...propsd} {...props}/>
                   </Containersd>
               } }
        />

          <Route exact path="/profile">
              <Containersd main={main} {...props}>
                <Profile main={main} {...props}/>
              </Containersd>
          </Route>

          <Route exact path="/perfil">
              <Containersd main={main} {...props}>
                <Profile main={main} {...props}/>
              </Containersd>
          </Route>


          <Route exact path="/editar-perfil">
              <Containersd main={main} {...props}>
                <Editarperfil main={main} {...props}/>
              </Containersd>
          </Route>


          <Route exact path="/checkout">
              <Containersd main={main} {...props}>
                <Checkout main={main} {...props}/>
              </Containersd>
          </Route>


        <Route exact path="/payment/:alias/:id"
               render={ propsd =>{
                 return <Containersd main={main} {...props}>
                   <Paymentprocess currency={main.currency} main={main} {...propsd} {...props}/>
                 </Containersd>
               } }
        />

        <Route exact path="/c/:alias/:aliasd/:id"
               render={ propsd =>{
                 return <Containersd main={main} {...props}>
                   <Detallecurso currency={main.currency} main={main} {...propsd} {...props}/>
                 </Containersd>
               } }
        />

        <Route exact path="/ordendetail/:id"
               render={ propsd =>{
                 return <Containersd main={main} {...props}>
                   <Ordenedetail currency={main.currency} main={main} {...propsd} {...props}/>
                 </Containersd>
               } }
        />

          <Route exact path="/makeappointment/:id"
                 render={ propsd =>{
                   return <Containersd main={main} {...props}>
                     <Appointment currency={main.currency} main={main} {...propsd} {...props}/>
                   </Containersd>
                 } }
          />

          <Route exact path="/mensajes">
            <Containersd addToCart={addToCart} product={product} handleClose={handleClose} toggleCart={toggleCart} removeItem={removeItem} showCart={showCart} main={main} domains={domains} changeLang={changeLang} {...props}>
              <Mensajes domains={domains} sessionuser={sessionuser} main={main} />
            </Containersd>
          </Route>


          <Route exact path="/s/:alias/:id"
                 render={ propsd =>{
                   return <Containersd main={main} {...props}>
                     <Servicesdetail main={main} {...propsd} {...props}/>
                   </Containersd>
                 } }
          />


          <Route exact path="/s/:aliascat/:alias/:id"
                 render={ propsd =>{
                   return <Containersd main={main} {...props}>
                     <Shopdetail currency={main.currency} main={main} {...propsd} {...props}/>
                   </Containersd>
                 } }
          />


          <Route exact path="/:aliascat/:alias/:id"
                 render={ propsd =>{
                     return <Containersd main={main} {...props}>
                       <Article main={main} {...propsd} {...props}/>
                     </Containersd>
                 } }
          />

          <Route exact path="/c/:categoryalias/:alias/:id"
                 render={ propsd =>{
                   return <Containersd main={main} {...props}>
                     <Content main={main} {...propsd} {...props}/>
                   </Containersd>
                 } }
          />

      </Switch>
    </BrowserRouter>
  }else{
    return <div className={'py-5 text-center'}><Loader/></div>
  }
}


function Containers(props) {
  let {domains,main,changeLang,showCart,cartContent,removeItem,product,handleClose,addToCart,toggleCart,qtyItems} = props
  let maind=main
  main=maind.result
  let menu=maind.menu
  let menuright=maind.menuright
  let languages=maind.languages
  const [logoval,setLogoval]=useState(main.logo_color)
  let servicehome=window.innerHeight-80;
  const [colormenu,setColormenu]=useState('color2')
  const [bgcolormenu,setBgColormenu]=useState('bgcolor2 color3')
  const [linkcolor,setLinkcolor]=useState('color3')

  useEffect(() => {
    window.onscroll = () => {
      let offset=window.pageYOffset
      if(offset >= servicehome){
        setLogoval(main.logo_negative)
        setColormenu('color3')
        setBgColormenu('bgcolor3 color1')
        setLinkcolor('color2')
      }
      if(offset < servicehome){
        setColormenu('color2')
        setBgColormenu('bgcolor2 color3')
        setLinkcolor('color3')
        setLogoval(main.logo_color)
      }
    }
  }, [main,servicehome]);

  const [showMenu,setShowMenu]=useState('fadeOut')
  return <div>
    <Bootstrap.Container fluid className={'px-0'}>
      <Bootstrap.Row className={'no-gutters'}>
        {showCart ?
            <Bootstrap.Col className={'border-right bgcartspaceall'} lg={3} md={4}>
              <div className={'card-body bgcartspace  h-100 w-100'}>
                <Cartcontent main={maind} toggleCart={toggleCart} removeItem={removeItem} domains={domains} cartContent={cartContent} {...props}/>
              </div>
            </Bootstrap.Col>
        :null}
            <Bootstrap.Col className={'border-right position-relative'}>
              <div className={'topmain position-relative'} style={{zIndex:14}}>
                <div className={'position-fixed no-gutters w-100 row'} style={{zIndex:16}}>
                  <div className={'col d-flex align-items-center'}>
                        <span className={'menubtntoggle '}>
                            <button onClick={()=>{
                              let value="fadeIn"
                              if(showMenu === "fadeIn"){
                                value="fadeOut"
                              }
                              setShowMenu(value)
                            }} className={'menuhometoggle py-5 px-3 d-inline-block '+colormenu}>
                                <Icons.Menu/>
                            </button>
                            <Bootstrap.Nav defaultActiveKey="/" style={{left:0, minWidth:250}} className={showMenu+" "+bgcolormenu+" flex-column position-absolute p-2 text-left text-uppercase"}>
                              {menu.map(item=>{
                                let name=item.nametranslation
                                name=name.replace(/(<([^>]+)>)/gi, "");
                                let link=item.linktranslation
                                link=link.replace(/(<([^>]+)>)/gi, "");
                                if(item.allow === 1 || (item.allow === 2 && domains.session !== null) || (item.allow === 3 && domains.session === null) ){
                                  return <Bootstrap.Nav.Link key={'menu'+item.id} className={linkcolor} href={link}>
                                    {name}
                                  </Bootstrap.Nav.Link>
                                }else{
                                  return null
                                }
                              })}
                            </Bootstrap.Nav>
                            <button
                                className={showCart ? 'bgcolor2 white border-0 p-3 ml-2 btntogglecarton '+colormenu : 'p-3 color2 ml-2 btntogglecartoff '+colormenu}
                                onClick={toggleCart}>
                                  <Icons.ShoppingCart />
                                  <Badge className={'ml-2'} variant={'light'}>{qtyItems}</Badge>
                            </button>

                        </span>
                  </div>
                  <div className={'col text-center'}>
                    <a href={'/'}><img alt={main.name} className={'py-3'} width={150} src={domains.domain+'/'+logoval}/></a>
                  </div>
                  <div className={'col d-flex justify-content-end align-items-center'}>
                    <Bootstrap.Nav defaultActiveKey="/"  className={"color3"}>
                      {menuright.map(item=>{
                        let name=item.nametranslation
                        name=name.replace(/(<([^>]+)>)/gi, "");
                        let link=item.linktranslation
                        link=link.replace(/(<([^>]+)>)/gi, "");
                        if(item.allow === 1 || (item.allow === 2 && domains.session !== null) || (item.allow === 3 && domains.session === null) ){
                          return <Bootstrap.Nav.Link key={'menu'+item.id} className={colormenu} href={link}>
                            <FontAwesomeIcon icon={item.iconvalue}/> <span className={'textmenuitem'}>{name}</span>
                          </Bootstrap.Nav.Link>
                        }else{
                          return null
                        }
                      })}
                    </Bootstrap.Nav>
                  </div>
                </div>
                {product !== null ? <Modal size={'lg'} show={product == null ? false : true} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{product.titletranslation}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col lg={4} md={4} sm={12}>
                        <div className={'w-100 squareimage'}
                             style={{ height:350, backgroundImage: 'url(' + domains.domain + '/' + product.img + ')'}}></div>
                      </Col>
                      <Col>
                        <div dangerouslySetInnerHTML={{__html:product.descriptiontranslation}}></div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={()=>addToCart(product,1)}>
                      {maind.traducciones.agregar_al_carrito}
                    </Button>
                  </Modal.Footer>
                </Modal> : null}
                {props.children}
              </div>
              {main.whatsapp && main.whatsapp !== null && <a 
                className='whatsAppLink btn btn-primary position-fixed' 
                style={{bottom:10, left:10, zIndex:99}}
                target={'_blank'}
                href={'https://wa.me/'+main.whatsapp}><Icons.MessageCircle /> Chat
                </a>
              }
              <div id={'contact'} className={'footer menubtntoggle text-center py-5'}>
                <img width={150} alt={main.name} src={domains.domain+'/'+main.logo}/>
                <div className={'my-3'}>
                  <div className={'social'}>
                    {main.facebook !== "" ? <a className={'px-3 d-inline-block'} href={main.facebook} rel="noreferrer" target={"_blank"}><Icons.Facebook/></a>:null}
                    {main.instagram !== "" ? <a className={'px-3 d-inline-block'} href={main.instagram} rel="noreferrer" target={"_blank"}><Icons.Instagram/></a>:null}
                  </div>
                  {main.phone !== "" ? <h5 className={'mt-3 mb-0 font-weight-light'}><a href={'tel:'+main.phone}>{main.phone}</a></h5> : null}
                  {main.schedule !== "" ? <p className={'my-2'}>{main.scheduletranslated}</p> : null}
                  {main.address !== "" ? <p className={'my-0'}>{main.address}</p> : null}
                  {main.address2 !== "" ? <p className={'my-0'}>{main.address2}</p> : null}
                  <p className={'my-0'}>{main.city !== "" ? main.city : null}, {main.state !== "" ? main.state : null}, {main.zipcode !== "" ? main.zipcode : null}</p>
                  {languages.map(lang=><span key={'lang'+lang.id} className={'langlink'}>{domains.language !== lang.id ? <button value={lang.id} className={'color2 p-3 text-uppercase'} onClick={(e=>changeLang(e))}>{lang.alias}</button>
                      : <span className={'color1 p-3 text-uppercase'}>{lang.alias}</span>}</span>)}
                </div>
              </div>
            </Bootstrap.Col>
      </Bootstrap.Row>
    </Bootstrap.Container>
    </div>

}

function Containersd(props) {
  let {toggleCart,domains,main,showCart,cartContent,removeItem,changeLang,product,handleClose,addToCart,qtyItems} = props
  let maind=main
  main=maind.result
  let menu=maind.menu
  let menuright=maind.menuright
  let languages=maind.languages
  const [showMenu,setShowMenu]=useState('fadeOut')
  return <Bootstrap.Row className={'no-gutters'}>
        {showCart ?
            <Bootstrap.Col className={'border-right bgcartspaceall'} lg={3} md={4}>
              <div className={'card-body bgcartspace h-100 w-100'}>
                <Cartcontent main={maind} toggleCart={toggleCart} removeItem={removeItem} domains={domains} cartContent={cartContent} {...props}/>
              </div>
            </Bootstrap.Col>
            :null}
        <Bootstrap.Col className={'border-right position-relative'} lg={showCart ? 9 : 12} md={showCart ? 8 : 12}>
          <Bootstrap.Container fluid className={'px-0'}>
            <div className={'topmain position-relative'} >
              <div className={'position-relative row no-gutters bgwhitetransparent'} style={{zIndex:16}}>
                    <div className={'col d-flex align-items-center'}>
                        <span className={'menubtntoggle'}>
                            <button onClick={()=>{
                              let value="fadeIn"
                              if(showMenu === "fadeIn"){
                                value="fadeOut"
                              }
                              setShowMenu(value)
                            }} className={'color2 py-5 px-3 d-inline-block togglebtnmenu'}>
                                <Icons.Menu/>
                            </button>
                            <Bootstrap.Nav defaultActiveKey="/" style={{left:0, minWidth:250}} className={showMenu+" flex-column bgcolor2 color3 position-absolute p-2 text-left text-uppercase"}>
                              {menu.map(item=>{
                                let name=item.nametranslation
                                name=name.replace(/(<([^>]+)>)/gi, "");
                                let link=item.linktranslation
                                link=link.replace(/(<([^>]+)>)/gi, "");
                                if(item.allow === 1 || (item.allow === 2 && domains.session !== null) || (item.allow === 3 && domains.session === null) ){
                                  return <Bootstrap.Nav.Link key={'menu'+item.id} className={'color3'} href={link}>
                                    {name}
                                  </Bootstrap.Nav.Link>
                                }else{
                                  return null
                                }

                              })}
                            </Bootstrap.Nav>
                            <button
                                className={showCart ? 'bgcolor2 white border-0 p-3 ml-2 btntogglecarton' : 'p-3 color2 ml-2 btntogglecartoff'}
                                onClick={toggleCart}>
                                <Icons.ShoppingCart />
                                <Badge className={'ml-2'} variant={'light'}>{qtyItems}</Badge>
                            </button>
                        </span>
                    </div>
                    <div className={'col text-center'}>
                        <a href={'/'}><img alt={main.name} className={'py-3'} width={150} src={domains.domain+'/'+main.logo}/></a>
                    </div>
                    <div className={'col d-flex justify-content-end align-items-center'}>
                            <Bootstrap.Nav defaultActiveKey="/"  className={"color3"}>
                                {menuright.map(item=>{
                                  let name=item.nametranslation
                                  name=name.replace(/(<([^>]+)>)/gi, "");
                                  let link=item.linktranslation
                                  link=link.replace(/(<([^>]+)>)/gi, "");
                                  if(item.allow === 1 || (item.allow === 2 && domains.session !== null) || (item.allow === 3 && domains.session === null) ){
                                    return <Bootstrap.Nav.Link key={'menu'+item.id} className={'color2'} href={link}>
                                      <FontAwesomeIcon icon={item.iconvalue}/> <span className={'textmenuitem'}>{name}</span>
                                    </Bootstrap.Nav.Link>
                                  }else{
                                    return null
                                  }
                                })}
                            </Bootstrap.Nav>
                    </div>
              </div>
              {product !== null ? <Modal size={'lg'} show={product == null ? false : true} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>{product.titletranslation}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col lg={4} md={4} sm={12}>
                      <div className={'w-100 squareimage'}
                           style={{ height:350, backgroundImage: 'url(' + domains.domain + '/' + product.img + ')'}}></div>
                    </Col>
                    <Col>
                      <div dangerouslySetInnerHTML={{__html:product.descriptiontranslation}}></div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={()=>addToCart(product,1)}>
                    {maind.traducciones.agregar_al_carrito}
                  </Button>
                </Modal.Footer>
              </Modal> : null}
              {props.children}
            </div>
            {main.whatsapp && main.whatsapp !== null && <a 
                className='whatsAppLink btn btn-primary position-fixed' 
                style={{bottom:10, left:10, zIndex:99}}
                target={'_blank'}
                href={'https://wa.me/'+main.whatsapp}><Icons.MessageCircle /> Chat
                </a>
              }
            <div id={'contact'} className={'footer menubtntoggle bgcolor1 color4 text-center py-5'}>
              <a href={'/'}><img alt={main.name} width={150} src={domains.domain+'/'+main.logo_negative}/></a>
              <div className={'my-3'}>
                {main.facebook !== "" ? <a className={'px-3 d-inline-block'} href={main.facebook} rel="noreferrer" target={"_blank"}><Icons.Facebook/></a>:null}
                {main.instagram !== "" ? <a className={'px-3 d-inline-block'} href={main.instagram} rel="noreferrer" target={"_blank"}><Icons.Instagram/></a>:null}
                {main.phone !== "" ? <h5 className={'mt-3 mb-0 font-weight-light'}>{main.phone}</h5> : null}
                {main.schedule !== "" ? <p className={'my-2'}>{main.scheduletranslated}</p> : null}
                {main.address !== "" ? <p className={'my-0'}>{main.address}</p> : null}
                {languages.map(lang=><span className={'langlink'} key={'langkey'+lang.id}>{parseFloat(domains.language) !== parseFloat(lang.id) ? <button value={lang.id} className={'p-3 text-uppercase color4'} onClick={(e=>changeLang(e))}>{lang.alias}</button>
                    : <span className={'p-3 text-uppercase color4'}>{lang.alias}</span>}</span>)}
              </div>
            </div>
          </Bootstrap.Container>
        </Bootstrap.Col>
      </Bootstrap.Row>
}


function App() {
    const [firsttime,setFirsttime]=useState(true)
    const [showCart,setShowCart]=useState(false)
    const [qtyItems, setQtyItems]=useState(0)
    const [main, setMain] = useState(null)
    const queryParams = new URLSearchParams(window.location.search)
    const mesag = queryParams.get("message");

    let sessionuser=JSON.parse(sessionStorage.getItem('user'))
    let sessioncart=sessionStorage.getItem("cart");
    if(sessionuser === undefined || sessionuser === ""){
        sessionuser=null
    }
    if(sessioncart === undefined || sessioncart === ""){
        sessioncart=[]
    }

    const [product,setProduct] = useState(null)
    const [cartContent,setCartContent]=useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setProduct(null)

  let sessionlang=sessionStorage.getItem('lang')
  if(sessionlang === undefined || sessionlang === "" || sessionlang === null){
    sessionlang=1
  }

  let protocol = window.location.protocol
  const [language,setLanguage]=useState(sessionlang)
  const [loadedlang,setLoadedlang]=useState(true)
  let currentdomain = window.location.host
  let href=window.location.href

  if (protocol !== 'https:' && currentdomain !== 'localhost:3000') {
    let newhref = href.replace("http:", "https:"  );

    window.location.replace(newhref)
  }

  let apicode="gjf94rwleskfwmalsf6547987"
  if(currentdomain === 'localhost:3000'){
    currentdomain='adelfabeauty.com'
  }
  let domain=protocol+'//'+currentdomain
  let domainapi=domain+'/cms/access/v1/'

  const domains = {
    'currentdomain':currentdomain,
    'domainapi':domainapi,
    'domain':domain+'/cms',
    'apicode':apicode,
    'currenturl':href,
    'language':language,
    'csfrtoken':'65654984656454kfd54fgikuh65489516',
    'session':sessionuser
  }

  const changeLang = (e) =>{
    let langid=e.target.value
    setLoadedlang(false)
    setLanguage(langid)
    sessionStorage.setItem('lang', langid)
    window.location.reload();
  }

  const toogleCart = () => {
    if(showCart){setShowCart(false)}else{setShowCart(true)}
  }

  const removeItem = (itemid,indexp) => {
      let arr=cartContent
      setCartContent('loading')
      let checkifexits=arr.map((it,index)=>{
          if(indexp === index ){
            arr.splice(index,1)
          }
          return null
      })

      Promise.all(checkifexits).then(()=>{
          sessionStorage.setItem("cart", JSON.stringify(arr));
          setCartContent(arr)
          setShowCart(true)
      })
  }

  const addToCart = async (item,type,price = null) => {
      let arrayv = []

      let currentValue=cartContent

      if(currentValue !== 'loading' && currentValue !== null){
          arrayv=currentValue
      }

      setCartContent('loading')

      let newadded={}
      if(typeof price ==='string' && price !== null){
          price=price.replace(",","")
          price=parseFloat(price)
          item.price = price
      }

      newadded['item']=item
      newadded['type']=type

      let lastarrv=arrayv
      let checkoncart = []

      if(arrayv !== null){
          checkoncart = await arrayv.filter( it => parseFloat(it.type) === parseFloat(type) && parseFloat(it.item.id)  === parseFloat(item.id))
      }


      if(checkoncart.length > 0){
          let position = checkoncart[0].position;
          //si existe producto
          if(!item.custom_price){
              // si no es custom price
              let newqty = checkoncart[0].qty+1;
              if(type === 1 && item.inventory >= newqty){
                  arrayv[position]['qty']=newqty;
              } else if(type === 2) {
                arrayv[position]['qty']=newqty;
              }

          }else{
            // si es custom price
              let position = checkoncart[0].position;
              if (item.price !== checkoncart[0].item.price) {
                  newadded['position']=lastarrv.length;
                  newadded['qty']=1;
                  arrayv.push(newadded);
              } else {
                  let newqty = checkoncart[0].qty+1;
                  arrayv[position]['qty']=newqty;
              }
          }
      }else{
          //si no existe producto
          newadded['qty']=1;
          if(lastarrv !== null){
              newadded['position']=lastarrv.length;
          }else{
              newadded['position']=0;
          }

          item.custom_price && item.price > 0 && arrayv.push(newadded);
          !item.custom_price && arrayv.push(newadded);
      }

      setShowCart(true)
      sessionStorage.setItem("cart", JSON.stringify(arrayv))

      setTimeout(()=>setCartContent(arrayv),500)
  }

  useEffect(()=>{
    if(cartContent !== 'loading'
        && cartContent !==null
        && cartContent !==undefined
        && cartContent.length > 0){
      let result=cartContent.reduce((prev,item)=>prev+item.qty,0)
      setQtyItems(result)
    }
  },[cartContent])

  if(firsttime){
      setFirsttime(false)
      let sessioncartload=JSON.parse(sessionStorage.getItem("cart"));
      setCartContent(sessioncartload)

      let clientid=null
      if(sessionuser !== null && sessionuser.id !== undefined){
          clientid=sessionuser.id
      }

      let objectotosend={'langid':domains.language,'clientid':clientid}

      let url = domains.domainapi+'mainData/'+domains.apicode
      fetching(url,objectotosend,setMain)
  }

  return <div className="h-100">
        {mesag !== null && mesag !== undefined && mesag !== "" ? <div className={'alert alert-info mt-3 mx-3'}>{mesag}</div> : null }
        <Contentget
            main={main}
            qtyItems={qtyItems}
            setQtyItems={setQtyItems}
            sessionuser={sessionuser}
            show={show}
            setShow={setShow}
            handleClose={handleClose}
            setProduct={setProduct}
            product={product}
            removeItem={removeItem}
            cartContent={cartContent}
            addToCart={addToCart}
            toggleCart={toogleCart}
            showCart={showCart}
            changeLang={(e)=>changeLang(e)}
            loadedlang={loadedlang}
            domains={domains} />
      </div>
}

export default App
