import React, {useState} from "react";
import {Loader, Title, Subtitle, Moneyformat, removeHTML} from './General'
import { Container, Col, Row, Button } from "react-bootstrap"
import {Helmet} from "react-helmet";


function Servicescategories(props) {
    const {domains, main, content }=props
    return(
        <div className={'allservices'}>
            <div className={'panoimg'} style={{backgroundImage:'url('+domains.domain+'/'+content.servicecontent.img+')'}}>
                <div className={'bgcolor1transparent w-100 h-100'}></div>
            </div>
            <Container className={'py-5 color2'}>
                <Row className={'pt-5'}>
                    <Col xs={12} md={8}>
                        <Title value={content.servicecontent.titletranslations}></Title>
                        <p className="mb-5">{removeHTML(content.servicecontent.descriptiontranslations)}</p>
                    </Col>
                    <Col>
                        <Button 
                            className={'mt-0 mb-5 text-white'} 
                            variant="primary" 
                            href={removeHTML(main.makeappointmentlink.linktranslated)}    
                        >
                            {removeHTML(main.makeappointmentlink.nametranslated)}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {content.servicecategory.map(ser=>{
                        return <Col hey={'catser'+ser.id} className={'mb-5'} xs={12} md={4}>
                                <a href={'/s/'+removeHTML(ser.aliastranslation)+'/'+ser.id}><div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+ser.img+')'}}></div>
                                    <Subtitle value={ser.titletranslation}></Subtitle></a>
                                    {ser.lowest_price.length > 0 ? <div className={'font-italic'}>{content.translations.desde} <Moneyformat value={ser.lowest_price[0].price}></Moneyformat></div> : null}
                                <div className={'my-3 text-justify'}>{removeHTML(ser.descriptiontranslation)}</div>
                                <a href={'/s/'+removeHTML(ser.aliastranslation)+'/'+ser.id} className={'btn rounded-0 py-3 mt-4 btn-block bordercolor2 color2'}>{content.translations.ver_todos}</a>
                            </Col>

                        }

                    )}

                </Row>
            </Container>
        </div>
    )
}

function Services(props) {
    let {main, domains} = props
    const [content,setContent]=useState(false)
    const fetchHome = () => {
        let objectotosend={'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getServices/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd)
                }else{
                    //setTickets([])
                }
            })
    }

    if(!content){
        fetchHome()
    }

    return <div className='home'>

            {content ? <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{removeHTML(content.servicecontent.titletranslations) + ' - ' + main.result.name}</title>
                    <meta name="description" content={removeHTML(content.servicecontent.descriptiontranslations)}/>
                </Helmet>
                <Servicescategories domains={domains} content={content} main={main}/>
            </div> : <div className={'py-5 text-center'}><Loader/></div>}

        </div>
}

export default Services;
