import React, {useEffect, useState} from "react";
import {Container, Col, Row, Modal, Button} from "react-bootstrap";
import {Loader, fetching, Subtitle, Moneyformat, Sideprofile} from "./General";
import {useElements,useStripe} from '@stripe/react-stripe-js';
import {Helmet} from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faClock
} from '@fortawesome/fontawesome-free-solid'
import Moment from "react-moment";

const CheckoutForm = (props) => {
    let {membership, domains,sessionuser, setMembership} = props
    const elements = useElements()
    const stripe = useStripe()

    const fetchCheckout = () =>{
        let formData = new FormData();
        formData.append('membership', JSON.stringify(membership.id));
        formData.append('client', JSON.stringify(sessionuser));
        formData.append('_csrfToken',domains.csfrtoken)
        fetch(domains.domainapi+'getCheckoutSessionStripe/'+domains.apicode, {
            method: 'POST',
            body:formData
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(session) {
                if(session !== null && stripe !== null){
                    setMembership(null)
                    return stripe.redirectToCheckout({ sessionId: session.id });
                }
            })
            .then(function(result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.

            });
    }

    useEffect(()=>fetchCheckout(),[])

    return <Loader/>
}

const Subscription = (props) => {
    let {domains,sessionuser,subscription,setSubscription,setShow, main} = props
    const [detail,setDetail] = useState(null)

    const fetchDetail = () => {
        let objectotosend={'langid':domains.language, 'client':sessionuser, 'suscriptionid':subscription.id}
        let url = domains.domainapi+'getDetailSuscriptiones/'+domains.apicode
        fetching(url,objectotosend,setDetail)
    }

    let endingdate
    let now

    if(detail !== null){
        endingdate=subscription.current_period_end
        now = Math.round(new Date().getTime() / 1000)
    }


    useEffect(()=>fetchDetail(),[])

    return <div>
        {detail === null ?
            <Loader/>
            :
            <div>
                <div  key={'suscrip'}  className={'border-top border-bottom py-2 d-flex justify-content-between align-items-center'}>
                    <div className={'d-flex justify-content-start align-items-center'}>
                        <div className={'mr-2'}>
                            <span className={'py-2 px-2 rounded bgcolor2'}>
                                <FontAwesomeIcon color={'white'} icon={faClock} />
                            </span>
                        </div>
                        <div>
                            <p className={'m-0'}>ID #{subscription.id} </p>
                        </div>
                    </div>
                    <div>
                        <button className={'btn text-white bgcolor2'} onClick={()=>setSubscription(null)}>{main.traducciones.volver}</button>
                    </div>

                </div>
                <div>
                    <Subtitle color={' my-3'} value={'Invoices'}/>
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Invoice Number</th>
                                <th>Customer</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detail.data.map(item=>
                                {
                                    let amount=item.amount_due/100
                                    return <tr>
                                        <td>
                                            <Moneyformat symbol={'$'} value={amount}></Moneyformat>
                                        </td>
                                        <td>
                                            {item.id}
                                        </td>
                                        <td>
                                            {item.customer_email}
                                        </td>
                                        <td>
                                            <Moment unix tz={'America/Santo_Domingo'} format="D MMM YYYY">{item.created}</Moment>
                                        </td>
                                    </tr>
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}
function Suscripciones(props) {
    let {domains, main, sessionuser} = props
    let [subscriptions,setSubscriptions]=useState(null)
    let [subscription,setSubscription]=useState(null)
    let [show,setShow]=useState(false)

    const fetchSuscripciones = () => {
        let objectotosend={'langid':domains.language, 'client':sessionuser}
        let url = domains.domainapi+'getSuscripciones/'+domains.apicode
        fetching(url,objectotosend,setSubscriptions)
    }

    const submitCancel = () => {
        let objectotosend={'langid':domains.language, 'id':subscription.id}
        let url = domains.domainapi+'submitCancelling/'+domains.apicode
        fetching(url,objectotosend,()=>null)
        setSubscriptions(null)
        setShow(false)
        setSubscription(null)
        fetchSuscripciones()
    }

    console.log(subscriptions)

    useEffect(()=>fetchSuscripciones(),[])

    if(sessionuser === null){
        window.location.href='/login?back=suscripciones'
    }

    return <Container>
            <Row>
                <Col>
                    <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                </Col>
                <Col xs={12} sm={12} md={9} lg={10}>
                    {subscriptions === null
                        ?
                            <Loader/>
                        :
                        <>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{subscriptions.content.titletranslations} - {main.result.name}</title>
                                <meta name="description" content={subscriptions.content.descriptiontranslations} />
                            </Helmet>
                            <Row className={'justify-content-center'}>
                                <Col className={'mb-5'} xs={12}>
                                    <Subtitle color={' mb-4'} value={subscriptions.traducciones.historial_de_suscripciones}/>
                                    <Modal show={show} onHide={()=>setShow(false)}>
                                        <Modal.Body>
                                            <Subtitle value={'¿Está seguro quiere cancelar la suscripción?'} color={'mb-3'}/>
                                            <div className={'mb-3'}>
                                                <Button className={'mr-3'} variant="secondary" onClick={()=>setShow(false)}>
                                                    No
                                                </Button>
                                                <Button variant="primary" onClick={()=>submitCancel()}>
                                                    Si
                                                </Button>
                                            </div>
                                            <small>Esto cancelará las futuras facturas por generarse. Si hay alguna ya generada, será la última en ser cargada.
                                                En caso de que quiera eliminar la factura actual no cobrada ya generada, debe de comunicarse con la administración.</small>
                                        </Modal.Body>

                                    </Modal>

                                    {subscription !== null
                                        ?
                                            <Subscription subscription={subscription} setSubscription={setSubscription} {...props} />
                                        :
                                        <div>
                                            {subscriptions.suscripciones.length > 0 ?
                                                <div>
                                                    {subscriptions.suscripciones.map(item => {
                                                        console.log(item)
                                                            let status=subscriptions.status.filter(itemd=>itemd.id === item.status)[0]
                                                            return <div  key={'suscrip' + item.id}  className={'border-top py-3 d-flex justify-content-between align-items-center w-100'}>
                                                                <div className={'d-flex justify-content-start align-items-center'}>
                                                                    <div className={'mr-2'}>
                                                                <span className={'py-2 px-2 rounded bgcolor2'}>
                                                                    <FontAwesomeIcon color={'white'} icon={faClock} />
                                                                </span>
                                                                    </div>
                                                                    <div>
                                                                        <p className={'m-0'}>ID #{item.id} <span className={'badge '+status.color}>{status.titletranslations}</span></p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }
                                                    )}
                                                </div>
                                                :
                                                <div className={'alert alert-info'}>{subscriptions.traducciones.no_hay_suscripciones}</div>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
    </Container>

}

export default Suscripciones;
