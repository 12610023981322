import React, {useState} from "react";
import {Loader, Title, Card, Moneyformat, removeHTML} from './General'

import {Helmet} from "react-helmet"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import {Button, Container, Row, Col} from "react-bootstrap";

function Shopdetail(props) {
    let addToCart=props.addToCart
    const id=props.match.params.id
    let {domains, main} = props
    const [content,setContent]=useState(false)
    const categories=[]
    const colors=[]
    const fetchHome = () => {
        let objectotosend={'langid':domains.language,'itemid':id}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getShopdetail/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === 0){
                    setContent(resultd.detail)
                }else{
                    //setTickets([])
                }
            })
    }

    if(!content){
        fetchHome()
        return <Loader/>
    }else{
        return <div className='home'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{content.titletranslated} - {main.result.name}</title>
                <meta name="description" content={content.descriptiontranslated} />
            </Helmet>
            <div className={'panoimg'} style={{backgroundImage:'url('+domains.domain+'/'+content.img+')'}}>
                <div className={'bgcolor1transparent w-100 h-100'}></div>
            </div>
            {content ?
                
                <div className={'py-5 my-5'}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className={'text-center'}>
                                    <Title className={'color1'} value={content.titletranslated} />
                                    <a className={'color1'} href={'/s/'+content.cataliastranslated+'/'+content.service_category.id}>{content.cattitletranslated}</a>
                                    <h4 className={'font-italic mt-3 mb-4'}> <Moneyformat symbol={'$'} value={content.price}></Moneyformat></h4>

                                </div>
                                <Card className={'bg-light mt-4 p-2'}>
                                    <div dangerouslySetInnerHTML={{__html:content.descriptiontranslated}}></div>
                                    <div className="text-center">
                                        <Button 
                                            className={'mt-4 text-white'} 
                                            variant="primary" 
                                            href={removeHTML(main.makeappointmentlink.linktranslated)}    
                                        >
                                            {removeHTML(main.makeappointmentlink.nametranslated)}
                                        </Button>
                                    </div>                                    
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                : <div className={'py-5 text-center'}><Loader/></div>}

        </div>
    }


}

export default Shopdetail;
