import React, {useState} from "react";
import {Loader,Title,Subtitle,ArticleBlock} from './General'
import * as Bootstrap from "react-bootstrap"
import Moment from "react-moment"
import 'moment-timezone'
import {Helmet} from "react-helmet";
import * as Icons from "react-feather"

function Articles(props) {
    const {domains,main,content,pagination}=props
    return <div className={'allservices'}>
            <div className={'panoimg'} style={{backgroundImage:'url('+domains.domain+'/'+content.content.img+')'}}>
                <div className={'bgcolor1transparent w-100 h-100'}></div>
            </div>
            <Bootstrap.Container className={'py-5 color2'}>
                <Bootstrap.Row className={'pt-5'}>
                    <Bootstrap.Col xs={12} md={8}>
                        <Title value={content.content.titletranslation}></Title>
                        <p className={'mb-5'}>{content.content.descriptiontranslation}</p>
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <Bootstrap.Row>
                    {content.articles.map(ser=>{
                        return <Bootstrap.Col key={'colomart'+ser.id} className={'mb-5'} xs={12} md={4}>
                                <ArticleBlock  main={main} domains={domains} producto={ser} btnclasscolor={"bordercolor2 color2"}/>
                            </Bootstrap.Col>})}
                </Bootstrap.Row>
            </Bootstrap.Container>
        </div>
}

function Articulos(props) {
    let {domains,main} = props
    const [content,setContent]=useState(false)
    const [pages,setPages]=useState(1)
    const [page,setPage]=useState(1)
    const fetchHome = () => {
        let objectotosend={'langid':domains.language,page:page}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getBlog/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                setContent(resultd)
            })
    }

    const settingPage = (number) => {
        setPage(number)
        setContent(false)
    }
    let pagination = []
    if(!content){
        fetchHome()
    }else{
        for (let i = 1; i<=pages; i++){
            let active
            if(i==page){
                active='active'
            }
            if(pages > 1){
                pagination.push(<li key={'pagination'+i} className={"page-item "+active}><a className="page-link" onClick={()=>settingPage(i)}>{i}</a></li>)
            }

        }
    }

    return <div className='home'>
            {content ? <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{content.content.titletranslation} - {main.result.name}</title>
                    <meta name="description" content={content.content.descriptiontranslation} />
                </Helmet>
                <Articles pagination={pagination} domains={domains} content={content} main={main}/>
                {content.pages > 0 ?
                    <nav className="mt-0 mb-5">
                        <ul className="pagination justify-content-center mb-5">
                            {pages > 1 && page > 1 ?<li className="page-item " key={'paginationfirst'}>
                                <a className="page-link first" onClick={()=>settingPage(1)}>
                                    <Icons.ArrowLeft size={18}/><Icons.ArrowLeft size={18}/>
                                </a>
                            </li> : null}
                            {page > 1 ?
                                <li key={'paginationback'} className="page-item ">
                                    <a className="page-link prev" onClick={()=>settingPage(page-1)}>
                                        <Icons.ArrowLeft size={18}/><Icons.ArrowLeft size={18}/>
                                    </a>
                                </li>
                                :null}
                            {pagination}
                            {page < pages ?
                                <li key={'paginationnext'} className="page-item ">
                                    <a className="page-link next" onClick={()=>settingPage(page+1)} aria-label="Next">
                                        <i className="simple-icon-arrow-right"></i>
                                    </a>
                                </li>
                                :null}

                            {page < pages ?
                                <li key={'paginationlast'} className="page-item ">
                                    <a className="page-link last" onClick={()=>settingPage(pages)}>
                                        <i className="simple-icon-control-end"></i>
                                    </a>
                                </li>
                                :null}
                        </ul>
                    </nav>
                    : null}

            </div> : <div className={'py-5 text-center'}><Loader/></div>}
        </div>
}

export default Articulos;
