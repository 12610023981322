import React, {useState} from "react";
import { Loader, MosaicProduct} from './General';
import {Container,Row,Col,Pagination,Form, Alert} from "react-bootstrap";
import {ChevronLeft, ChevronRight} from 'react-feather';
import {Helmet} from "react-helmet";


const Category = (props) => {
    let {domains,main,addToCart, setProduct, match} = props
    const [products,setProducts] = useState(null)
    const [page,setPage] = useState(1)
    const [message,setMessage] = useState(null)
    const [keyword,setKeyword] = useState(null)

    let dom = window.location.origin
    let href = window.location.href
    const fetchProducts = () => {
        let objectotosend={
            langid:domains.language,
            catname:match.params.catname,
            keyword:keyword,
            page:page}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getProductsFromCat/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setProducts(resultd)
                    if(resultd.alert !== undefined){
                        setMessage(resultd.alert)
                    }else{
                        setMessage(null)
                    }
                }
            })
    }

    const changePage = page => {
        setPage(page)
        setProducts(null)
    }

    const filter = (val) => {
        setPage(1)
        if(val ===" " || val === ""){
            val=null
        }
        setKeyword(val)


        setProducts(null)
    }

    if(products == null){
        fetchProducts()
    }

    const pagination = () => <div className={'d-flex align-items-center'}>
        <Pagination size={'sm'} className={' mb-0'}>
            <Pagination.Item onClick={()=>changePage(page-1)} key={'1'} disabled={page<=1}>
                <ChevronLeft />
            </Pagination.Item>
            <Pagination.Item onClick={()=>changePage(page+1)} key={'2'} disabled={page >= products.pages}>
                <ChevronRight/>
            </Pagination.Item>
        </Pagination></div>

    return <Container>
        {products == null ?
            <div className={'pb-5 text-center'}>
                <Loader/>
            </div>
            :
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{products.category.titletranslation} - {main.result.name}</title>
                    <meta name="description" content={products.category.descriptiontranslation} />
                    <meta property="og:url" content={ href } />
                    <meta property="og:title" content={products.category.titletranslation} />
                    <meta property="og:image" content={domains.domain + '/' + products.category.img} />
                    <meta property="og:image:secure_url" content={domains.domain + '/' + products.category.img} />
                    <meta property="og:image:type" content="image/jpeg" />
                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="300" />
                    <meta name="twitter:card" content={domains.domain + '/' + products.category.img} />
                    <meta property="twitter:domain" content={ dom } />
                    <meta property="twitter:url" content={ href } />
                    <meta name="twitter:title" content={products.category.titletranslation + "-" + main.result.name} />
                    <meta name="twitter:description" content={products.category.descriptiontranslation} />
                    <meta name="twitter:image" content={domains.domain + '/' + products.category.img} />

                </Helmet>

                {products.products.length > 0 ?
                    <>
                        <Row className={'mt-4 headershop'}>
                            <Col xs={12} md={4} className={'d-flex align-items-center'}>
                                <Form.Control onBlur={(e)=>filter(e.target.value)} className={'d-inline searcher form-control'} defaultValue={keyword} type="text" placeholder={main.traducciones.filtrar_por_palabras} />
                                <a
                                    href={products.link.linkTranslation}
                                    className={'btn color2'}>{main.traducciones.volver}</a>
                            </Col>
                            <Col className={'pagesinfo'} xs={12} md={4}>
                                <p className={'py-3 mb-0'}>{products.translations.total_de} {products.countall} {main.traducciones.productos} | {products.translations.pagina} {page} {products.translations.de} {products.pages}</p>
                            </Col>
                            <Col  xs={12} md={4} className={'pagina d-flex'}>
                                <div>
                                    {pagination()}
                                </div>
                            </Col>
                        </Row>
                        {message !== null ? <Row className={'py-5'}>
                            <Col>
                                <Alert variant={message.alerttype}>{message.alert}</Alert>
                            </Col>
                        </Row>: null}

                        <Row className={'pt-5'}>
                            {products.products.map(item=>{
                                    return <Col key={'prod'+item.id} className={'mb-5'} xs={12} lg={3} md={4}>
                                        <MosaicProduct main={main} domains={domains} item={item} addToCart={addToCart} setProduct={setProduct} />
                                    </Col>
                                }
                            )}
                        </Row>
                        <Row className={'pb-5'}>
                            <Col>
                                {pagination()}
                            </Col>
                        </Row>
                    </>
                    : <Row>
                        <Col>
                            <a
                                href={products.link.linkTranslation}
                                className={'btn color2'}>{main.traducciones.volver}</a>
                            <Alert className={'w-100 mt-3 mb-5'} variant={'info'}>No hay productos en esta categoría.</Alert>
                        </Col>
                    </Row>}
            </>
        }

    </Container>
}


export default Category;
