import React, {useState, useEffect} from 'react';
import {Sideprofile,Title, cancelOrder, Loader} from './General'
import 'moment-timezone';
import Moment from 'react-moment';
import * as Bootstrap from "react-bootstrap"

function Ordenedetail (props){
    let {domains, main, sessionuser}=props
    const [ orden, setOrden ]=useState(null)

    let orderid=props.match.params.id

    const loadContent = () => {
        if(sessionuser !== null){
            let params = {orderid:orderid, langid:domains.language}
            params=btoa(JSON.stringify(params))
            let url=domains.domainapi+'getordendetail/'+domains.apicode+'/'+params
            fetch(url).then(function (response) {
                return response.json()
            }).then( async (jsonvalue) => {
                var checkifAppoint = await jsonvalue.order_items.filter(it => it.type === 1 && it.serviceid === 35);
                if(jsonvalue.order.pendingappointment === true && checkifAppoint && (jsonvalue.order.statusid === 4 || jsonvalue.order.statusid === 3)){
                    openAppointment();
                }
                setOrden(jsonvalue)
            })
        }else{
            window.location.href = '/login';
        }
    }

    const openAppointment = () => {
        let params = {orderid:orderid, langid:domains.language}
            params=btoa(JSON.stringify(params))
        let url=domains.domainapi+'openAppointment/'+domains.apicode+'/'+params

        fetch(url).then(function (response) {
            return response.json()
        }).then((jsonvalue)=>{
           if(jsonvalue){
                window.location.href='https://booking.setmore.com/scheduleappointment/9e31b6d2f8149e15a3b2d12af58ccd89b4a0ac06/services/3bcef0e2-9d9c-40e7-9846-db703f3ae000';
                //setTimeout(()=>window.location.reload(), 250)
           }
        })
    }
    
    useEffect(()=>loadContent(),['orden'])
    let appoints
    if( orden !== null ){
        appoints = orden.order_items.filter(it => it.type === 2)
        appoints = appoints.filter(it => it.orders_appointment.length < it.qty)
    }

    return <div className={'ordenes'}>
            <Bootstrap.Container fluid={true}>
                {orden !== null ?
                    <Bootstrap.Row>
                        <Bootstrap.Col>
                            <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                        </Bootstrap.Col>
                        <Bootstrap.Col xs={12} sm={12} md={9} lg={10}>
                            <Title className={'mb-5'} value={'Detalle de la orden'} />
                            <a className="btn btn-primary" href="/orders" >Volver órdenes</a>
                            {orden.order.statusid === 1 || orden.order.statusid === 5 ?
                                <Bootstrap.Button href={'/payment/'+orden.order.payment_method.action+'/'+orden.order.id} className={'btn btn-success ml-3'}>Hacer pago</Bootstrap.Button>
                            :null}
                            {orden.order.statusid === 1 ?
                                <Bootstrap.Button onClick={()=>cancelOrder(orden.order.id, domains)} className={'btn btn-secondary text-white ml-3'} >Cancelar</Bootstrap.Button>
                            :null}
                            {orden.order.statusid === 1254 && appoints.length > 0 && <button onClick = {() => openAppointment()} className='btn btn-primary ml-2'>Abrir cita</button>}
                            <div className={'card card-body my-5'}>
                                <div dangerouslySetInnerHTML={{__html:orden.order.billdetail}}></div>
                            </div>
                            {orden.order.orders_transactions.length > 0 ?
                                <div id={'transactions'} >
                                    <Title className={'mb-5'} value={'Transacciones'}/>
                                    <div className={'mb-5'}>
                                        {orden.order.orders_transactions.map(item => <div>
                                            <div className={'mb-0'}>
                                                <ul className="list-group list-group-horizontal w-100 mb-3">
                                                    <li className="list-group-item" style={{width: 90}}>{item.id}</li>
                                                    <li className="list-group-item" style={{width: '65%'}}>
                                                        <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                                                    </li>
                                                    <li className="list-group-item"
                                                        style={{width: 150}}>${item.amount}</li>
                                                    <li className="list-group-item"><span
                                                        className={item.status ? "badge badge-success" : "badge badge-danger"}>{item.status ? "Aprobado" : "Declinado"}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                                :
                                null
                            }
                        </Bootstrap.Col>
                </Bootstrap.Row> : <Loader/> }
            </Bootstrap.Container>
        </div>
}
export default Ordenedetail;
