import React, {useEffect, useState} from "react";
import {Loader, StructurePrice, Subtitle} from './General'
import {Container, Row, Col, Button} from "react-bootstrap"
import {Helmet} from "react-helmet";


const ProductDetail = (props) =>{
    const [product,setProduct] = useState(null)
    const [price,setPrice]=useState(0)
    let {domains, match, addToCart, main} = props
    let id = match.params.id
    id = id.split('-');
    id = id[id.length-1]

    let dom = window.location.origin
    let href = window.location.href

    const fetchProduct = () => {
        let objectotosend={
            langid:domains.language,
            id:id}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getProductDetail/'+domains.apicode+'/'+objectotosend

        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === 0){
                    setProduct(resultd)
                    setPrice(resultd.product.price)
                }
            })
    }

    useEffect(()=>fetchProduct(),[])

    return product !== null ? <Container className={'py-5'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{product.product.titletranslation} - {main.result.name}</title>
                <meta name="description" content={product.product.descriptiontranslation} />
                <meta property="og:url" content={ href } />
                <meta property="og:title" content={product.product.titletranslation} />
                <meta property="og:image" content={domains.domain + '/' + product.product.img} />
                <meta property="og:image:secure_url" content={domains.domain + '/' + product.product.img} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="300" />
                <meta name="twitter:card" content={domains.domain + '/' + product.product.img} />
                <meta property="twitter:domain" content={ dom } />
                <meta property="twitter:url" content={ href } />
                <meta name="twitter:title" content={product.product.titletranslation + "-" + main.result.name} />
                <meta name="twitter:description" content={product.product.descriptiontranslation} />
                <meta name="twitter:image" content={domains.domain + '/' + product.product.img} />

            </Helmet>
           <Row className={'py-5'}>
                <Col lg={4} md={4} sm={12}>
                <div className={'w-100 h-100 squareimage square'}
                    style={{  backgroundImage: 'url(' + domains.domain + '/' + product.product.img + ')'}}></div>
                </Col>
                <Col>
                    <a
                        href={product.link.linkTranslation+'/'+product.product.cataliastranslation}
                        className={'btn color2 pl-0'}>{main.traducciones.volver}</a>

                    <Subtitle value={product.product.titletranslation}/>
                    <div className={'mt-4'} dangerouslySetInnerHTML={{__html:product.product.descriptiontranslation}}></div>
                    <div className={'pt-4'}>

                        <StructurePrice main={main} item={product.product} setPrice={setPrice} price={price}/>
                        <div>
                            <Button disabled={product.product.track_inventory && product.product.inventory < 1 && true } className={'mt-4'} variant="primary" onClick={()=>addToCart(product.product,1,price)}>
                                {main.traducciones.agregar_al_carrito}
                            </Button>
                        </div>
                        
                    </div>
                </Col>
            </Row>

        </Container>
        :
        <Loader/>
}

export default ProductDetail;
