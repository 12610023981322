import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {Loader, toURI} from './General'
import Select from "react-select";

function Crearcuenta(props){
    let domains=props.domains
    let main = props.main
    const [loading,setLoading]=useState(false)

    const search = window.location.search
    const params = new URLSearchParams(search)
    const backlink = params.get('back');

    const validateFields = () => {

        let fields =[]
        fields = document.getElementsByClassName('validate')
        fields = Array.from(fields)
        let objecttosend=new FormData()
        let empty=0
        let validatepass=0
        let passfield=document.getElementById('password')
        let pass2field=document.getElementById('password2')

        fields.map(fields => {
            let namefield=fields.getAttribute('name')
            let validationspace
            validationspace=document.getElementById('validatespace'+namefield)
            validationspace.innerHTML=''
            let value=fields.value
            if(value === ""){
                empty++
                validationspace.innerHTML='<div class="alert alert-warning py-1 mt-2">Este campo no puede estar vacío.</div>'
            }else{
                objecttosend.append(namefield,value)
                if(namefield === 'email'){
                    let params={
                        email:value}
                        params=btoa(JSON.stringify(params))
                    let url =domains.domainapi+'validateEmail/'+domains.apicode+'/'+params
                    fetch(url,{
                        method:'GET',
                        credentials:'include'
                    }).then((result)=>{
                        result.json()
                    })
                    .then((json)=>{
                        if(json === true){
                            empty++
                            validationspace.innerHTML='<div class="alert alert-warning py-1 mt-2">Este correo ya está siendo utilizado.</div>'
                        }
                    })
                }
                if(namefield === 'password2' && (passfield.value === pass2field.value) ){
                    validatepass = 1
                }else{
                    document.getElementById('validatespacepassword2').innerHTML='<div class="alert alert-warning py-1 mt-2">Las contraseñas no coinciden.</div>'
                }
            }
            return true
        })

        let agreement=document.getElementById('agree').checked
        let validationagreet=document.getElementById('validatespaceagree')

        if(!agreement){
            empty++
            validationagreet.innerHTML='<div class="alert alert-warning py-1 mt-2">Debe de estar de acuerdo con los términos y condiciones de uso.</div>'
        }else{
            validationagreet.innerHTML=''
        }
        if(empty === 0 && validatepass === 1){
            let paramstopass={langid:domains.language}
            paramstopass=btoa(JSON.stringify(paramstopass))
            objecttosend.append('_csrfToken',domains.csfrtoken)
            let header = new Headers({
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'multipart/form-data'
            })
            let sentData={
                method:'POST',
                header: header,
                body:objecttosend
            }
            let url =domains.domainapi+'sendRegistration/'+domains.apicode+'/'+paramstopass
            fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.result === 'success'){
                    let result=JSON.stringify(json.item)
                    sessionStorage.setItem('user', result)
                    let url = main.traducciones.profilelink
                    if(backlink !== null){
                        url='/'+backlink
                    }
                    window.location.href = url;
                }else{
                    setLoading(false)
                    console.log(json)
                }
            })
        }else{
            setLoading(false)
        }
    }

    return (
        <div className="signup-panel p-0">
            <div className="row my-0 pt-5">
                <div className="col-12 col-md-8">
                    <div className={'pb-5 mb-5 px-5'}>
                        <h4 className="signup-title">{main.traducciones.crea_tu_cuenta}</h4>
                        <h5 className="signup-subtitle mb-0">{main.traducciones.solo_te_toma_un_minuto}</h5>
                        <div className="signup-form mt-3">
                            <div className="row">
                                <div className="col-sm-6 mt-3">
                                    <div className="form-group">
                                        <label>{main.traducciones.name}</label>
                                        <input type="text" name={'nombre'} className="form-control validate" />
                                        <div id={'validatespacenombre'}></div>
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <div className="form-group">
                                        <label>{main.traducciones.apellido}</label>
                                        <input type="text" name={'apellido'} className="form-control validate"
                                        />
                                        <div id={'validatespaceapellido'}></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mg-b-5">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input name={'email'} type="email" className="form-control validate"
                                        />
                                        <div id={'validatespaceemail'}></div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={'row'}>
                                        <div className={'col-12 col-md-6'}>
                                            <div className="form-group">
                                                <label>{main.traducciones.contrasena}</label>
                                                <input type="password" name={'password'} className="form-control validate" id={'password'}  />
                                                <div id={'validatespacepassword'}></div>
                                            </div>
                                        </div>
                                        <div className={'col-12 col-md-6'}>
                                            <div className="form-group">
                                                <label>{main.traducciones.confirmar_contrasena}</label>
                                                <input type="password" name={'password2'} className="form-control validate" id={'password2'}  />
                                                <div id={'validatespacepassword2'}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mg-b-30">
                                <div className="custom-control custom-checkbox mb-4 mt-3">
                                    <input type="checkbox" className="custom-control-input" id="agree" />
                                    <label className="custom-control-label tx-sm" htmlFor="agree">
                                        {main.traducciones.he_leido_y_estoy_de_acuerdo_con_los} <a className={'color2'} href={'/c/legalidades/terminos-de-uso/6'}>{main.traducciones.terminos_de_uso}</a> {main.traducciones.y} <a className={'color2'} href={'/c/legalidades/politicas-de-privacidad/7'}>{main.traducciones.politicas_de_privacidad}</a>
                                    </label>
                                </div>
                                <div id={'validatespaceagree'}></div>
                            </div>
                            <div className="form-group d-flex mg-b-0">
                                {!loading ? <button onClick={validateFields} className="btn btn-primary btn-uppercase btn-block">{main.traducciones.crear_cuenta}</button> : <Loader/>}
                            </div>
                        </div>
                        <p className="mg-t-auto mg-b-0 ">{main.traducciones.ya_tiene_cuenta} <a
                            href="/login" className={'color2'}>{main.traducciones.identificate}</a></p>
                    </div>
                </div>
                <div className="col-12 col-md-4 py-0">
                    <div className={'bgimgvertical'} style={{backgroundSize:'cover',backgroundImage:'url('+domains.domain+'/'+main.result.img+')',height:'100%'}}></div>
                </div>
            </div>
        </div>
    )

}

export default Crearcuenta;
