import React, {useState,useEffect} from 'react';
import 'moment-timezone';
import * as Bootstrap from "react-bootstrap";

function Access (props){
    console.log('paso')
    let domains=props.domains
    const params = new URLSearchParams(window.location.search)
    let client_id=params.get('client_id')
    let access_token = window.location.hash.substr(1);
    access_token=access_token.replace("access_token=","")
    let mechant_id=params.get('merchant_id')

    const loadingrequest = () => {
        let objectotosend={'langid':domains.language,'access_token':access_token,'mechant_id':mechant_id,'client_id':client_id}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'accessAuthCode/'+domains.apicode+'/'+objectotosend
        console.log(url)
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.href !== undefined){
                    //window.location.href=resultd.href
                }
            })
    }
    useEffect(() => {
        loadingrequest()
    })

    return(
        <div className={'access py-5'}>
            <Bootstrap.Container fluid={true}>
                <Bootstrap.Row>
                    <Bootstrap.Col xs={12} sm={12} md={9} lg={10}>
                        <p>Saving authcode...</p>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </Bootstrap.Container>
        </div>
    )
}
export default Access;
