import React, {useState} from "react";
import ReactDOM from "react-dom";
import {Loader, Moneyformat} from './General'

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function Clover(props) {
    let {domains,order,main,content}=props
    let privatekey
    let merchantid
    const submitProcess = () => {
        var form = document.getElementById('payment-form');
       form.submit();
    }

    let paymentdetail=btoa(JSON.stringify(content.payment))
    let stringorder=JSON.stringify(order)
    setTimeout(()=>submitProcess(),500)
    return <form method={'POST'} id={'payment-form'} action={'https://adelfabeauty.com/clover/index.php'}>
        <input type={'hidden'} name={'logo'} value={domains.domain+'/'+main.result.logo} />
        <input type={'hidden'} name={'order'} value={stringorder} />
        <input type={'hidden'} name={'orderid'} value={order.id} />
        <input type={'hidden'} name={'apicode'} value={domains.apicode} />
        <input type={'hidden'} name={'domainapi'} value={domains.domainapi} />
        <input type={'hidden'} name={'csfrtoken'} value={domains.csfrtoken} />
        <input type={'hidden'} name={'paymentdetail'} value={paymentdetail} />
        <Loader />
    </form>
}

function Paymentprocess(props) {
    const id=props.match.params.id
    const alias=props.match.params.alias
    let domains = props.domains
    let main = props.main
    const [content,setContent] = useState(false)
    const [message,setMessage] = useState(false)
    const createOrder = (data, actions) => {
        let total=parseFloat(content.order.total)
        let dollarcurrency=parseFloat(content.currencydollar.exchange)
        if(content.currency.id === 1){
            total=dollarcurrency*total
        }
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: total.toFixed(2),
                    },
                },
            ],
        });

    }

    const onApprove = (data, actions) => {
        actions.order.capture().then(function(details) {
            if(details.status === "COMPLETED"){
                setMessage(false)
                let total=parseFloat(content.order.total)
                let dollarcurrency=parseFloat(content.currencydollar.exchange)
                if(content.currency.id === 1){
                    total=dollarcurrency*total
                }
                let objectotosend={'total':content.order.total,'currencyid':content.currency.id, exchanged:total,orderid:content.order.id,'transaccionid':details.id}
                objectotosend=btoa(JSON.stringify(objectotosend))
                let urld = domains.domainapi+'receivingPaypalPayment/'+domains.apicode+'/'+objectotosend
                fetch(urld)
                    .then(result=>result.json())
                    .then(resultd=>{

                        window.location.href ='/ordenes';
                    })
            }else{
                setMessage('No se pudo completar su pago.')
            }

        });

    }


    const fetchHome = () => {
        let objectotosend={'alias':alias,'itemid':id,'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getPaymentchosen/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                setContent(resultd)
            })
    }


    if(!content){
        fetchHome()
        return <div className={'py-5 text-center'}><Loader/></div>
    }else{
        let order=content.order
        if(order.statusid === 1 || order.statusid === 5){
        }else{
            return <div className={'container py-5'}><div className={'alert alert-info'}>El estado de esta orden es: {order.orders_type.name}</div></div>
        }
        return <div className='payment'>
            {content.payment.id === 3 ?
                <div className={'container text-center py-3 rounded'}>
                    <div className={'bg-light d-inline-block p-5 mb-5'}>
                        <h2 className={'mb-0'}>Órden #{content.order.id}</h2>
                        <span className={'badge badge-info my-3'}>{order.orders_type.name}</span>
                        <p className={'mb-0'}>Total <Moneyformat symbol={'$'} value={content.order.total} /></p>
                    </div>
                    {message ? <div className={'alert alert-warning'}>{message}</div> : null}
                    <PayPalButton
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                    />
                </div> :null}

            {content.payment.id === 1 ?
                <div className={'container py-3 rounded'}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4'}>
                            <div className={'bg-light p-5 mb-5'}>
                                <h2 className={'my-0'}>Órden #{content.order.id}</h2>
                                <span className={'badge badge-info my-3'}>{order.orders_type.name}</span>
                                <p className={'mb-0'}>Total <Moneyformat symbol={'$'} value={content.order.total} /></p>
                            </div>
                        </div>
                        <div className={'col'}>
                            {message ? <div className={'alert alert-warning'}>{message}</div> : null}
                            <Clover domains={domains} content={content} order={order} main={main}/></div>
                    </div>

                </div> :null}
        </div>
    }
}

export default Paymentprocess;
