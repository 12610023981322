import * as Bootstrap from "react-bootstrap"
import React, {useState, useRef} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fontawesome from '@fortawesome/fontawesome'
import {
    faUserEdit,
    faTachometerAlt,
    faCalendarCheck,
    faShoppingCart,
    faComment,
    faSignOutAlt,
    faChevronLeft,
    faStar,
    faMoneyBill
} from '@fortawesome/fontawesome-free-solid'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import * as Icons from "react-feather";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Moment from "react-moment";
fontawesome.library.add(faUserEdit, faTachometerAlt, faCalendarCheck , faShoppingCart,faComment,faSignOutAlt, faMoneyBill);

export function Sideprofile(props) {
    let sessionuser=props.sessionuser
    let main=props.main
    let domains=props.domains
    return (<Bootstrap.Nav variant={'pills'} defaultActiveKey={domains.pathname} className={'flex-column mb-5'}>
        {main.menuside.map(item=><Bootstrap.Nav.Item key={'profilesidemenu'+item.id}><Bootstrap.Nav.Link className={'color1'} href={item.linktranslation}><FontAwesomeIcon icon={item.iconvalue}/> {item.nametranslation}</Bootstrap.Nav.Link></Bootstrap.Nav.Item>)}
    </Bootstrap.Nav>)

}

export const ArticleBlock =(props)=>{
    let {producto,domains,main,btnclasscolor}=props
    let description = producto.shortdescriptiontranslation
    description=description.substring(0,100)
    return <div className={'articleitem'}><div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+producto.img+')'}}></div>
    <Subtitle value={producto.titletranslation}></Subtitle>
    <Moment format="MMM, DD YYYY">{producto.date_created}</Moment>
    <p className={'mt-2'}>{main.traducciones.por} {producto.admin_user.name}</p>
    <div className={'my-3 text-justify'}>{description}...</div>
    <a href={'/'+main.traducciones.blogaliastranslation+'/'+producto.aliastranslation+'/'+producto.id} className={'btn rounded-0 py-3 mt-4 btn-block '+btnclasscolor}>{main.traducciones.leer_mas}</a></div>
}

export function cancelOrder(orderid,domains) {
    let params = {orderid:orderid}
    params=btoa(JSON.stringify(params))
    let url=domains.domainapi+'cancelOrderFrom/'+domains.apicode+'/'+params
    fetch(url).then(function (response) {
        return response.json()
    }).then((jsonvalue)=>{
        return true
    })
}

export const fetching = (url,params=null,fn) => {
    if(params !==  null){
        params=btoa(JSON.stringify(params))
    }

    let urlpass=url+'/'+params
    console.log(urlpass)
    fetch(urlpass)
        .then(result =>{
                if (result.ok) {
                    return result.json();
                } else {
                    console.error(result.error())
                }
            }
        )
        .then(resJSON => {
                if (fn !== null && fn !== undefined) {
                    fn(resJSON)
                }
            }
        )
        .catch((error) => {
                console.error(error)
            }
        )
}


export function Productdetail(props) {
    let { content, addToCart, main }=props
    let price=parseFloat(content.price)
    return (
        <div>
            <div className={'py-5 my-5'}>
                <Bootstrap.Container>
                    <Bootstrap.Row>
                        <Bootstrap.Col xs={12}>
                            <div className={'text-center'}>
                                <Title className={'color1'} value={content.titletranslated} />
                                <a className={'color1'} href={'/s/'+content.cataliastranslated+'/'+content.service_category.id}>{content.cattitletranslated}</a>
                                <h4 className={'font-italic mt-3 mb-4'}> <Moneyformat symbol={'$'} value={price}></Moneyformat></h4>
                                <Bootstrap.Row>
                                    <Bootstrap.Col xs={12} md={12}>
                                       <button className={'bgcolor2 border-0 text-uppercase p-3 text-white ml-2'} onClick={()=>addToCart(content)} variant={'primary'} size={'lg'}>{main.traducciones.comprar}</button>
                                    </Bootstrap.Col>
                                </Bootstrap.Row>
                            </div>
                            <Card className={'bg-light mt-4 p-2'}>
                                <div dangerouslySetInnerHTML={{__html:content.descriptiontranslated}}></div>
                            </Card>
                        </Bootstrap.Col>
                    </Bootstrap.Row>
                </Bootstrap.Container>
            </div>
        </div>
    )
}

export function StructurePrice(props) {
    let {item,setPrice,price, main} = props
    let finalprice=item.price
    let discount=0
    if(main !== undefined && main.client !== null && main.client.active_membership !== null && main.client.active_membership.membership.product_discount > 0){
        discount=main.client.active_membership.membership.product_discount
    }
    const priceChanger = useRef()
    return item.custom_price
        ?
        <>
            <label><small>Escriba el monto que quiera agregar</small></label>
            <input ref={priceChanger} placeholder={'$'}
                   onKeyUp={(e)=>{
                       var value=e.target.value
                       var formated = formatingNumber(value)
                       setPrice(formated)
                       priceChanger.current.value=formated
                   }
                   }
                   className={'form-control mt-2 amountput'}
                   defaultValue={price}
                   type={'text'}/>
        </>
        :
        <Moneyformat discount={discount} value={finalprice}/>
}

export function MosaicSuscription(props) {
    let {domains,setMembership,main,item,currentmemberships} = props
    const selectingMembership = () => {
        let stringifyed=JSON.stringify(item)
        sessionStorage.setItem('membership',stringifyed)
        setMembership(item)
    }

    return <Row className={'no-gutters itemmembers'}>
        {props.onlyInfo ?
            null
            :
            <Col xs={5} md={12}>
                <button className={'link m-0 p-0'} onClick={()=>selectingMembership(item)}>
                    <div className={'w-100 squareimage square'} style={{backgroundImage:'url('+domains.domain+'/'+item.img+')'}}></div>
                </button>
            </Col>
        }
        <Col xs={7} md={12}>
            <div className={'articlespacecontet'}>
                {currentmemberships !== null && currentmemberships.membershipid === item.id
                    ?
                    <div>
                        <span className={'badge bgcolor1 text-white mt-3'}>Suscripcion activa</span>
                    </div>
                    :
                    null
                }
                {props.onlyInfo ?
                    <Subtitle color={'mt-3'} value={item.titletranslations}></Subtitle>
                    :
                    <button
                        className={'link'}
                        onClick={()=>selectingMembership(item)}>
                        <Subtitle color={'mt-3'} value={item.titletranslations}></Subtitle>
                    </button>
                }

                <div className={'my-3'}>
                    <Moneyformat value={item.price}/>
                </div>
                <div className={'color2'} dangerouslySetInnerHTML={{__html:item.descriptiontranslations}}></div>
                {props.onlyInfo || currentmemberships !== null ?
                    null
                    :
                    <button onClick={() => selectingMembership(item)}
                            className={'btn rounded-0 py-3 mt-4 btn-block bordercolor1 color1'}>{main.traducciones.suscribe}</button>
                }
            </div>
        </Col>
    </Row>
}


export function MosaicProduct(props) {
    let { domains,item,addToCart,setProduct,main } = props
    const [price,setPrice]=useState(item.price)
    let prod = item

    if(!item.titletranslation){
        prod = item.product
    }

    return <Row className={'no-gutters itemproduct'}>
        <Col xs={5} md={12}>
            <button className={'link m-0 p-0'} onClick={()=>setProduct(item)}>
                <div className={'w-100 squareimage square'} style={{backgroundImage:'url('+domains.domain+'/'+item.img+')'}}></div>
            </button>
        </Col>
        <Col xs={7} md={12}>
            <div className={'articlespacecontet'}>
                <button
                    className={'link'}
                    onClick={()=>setProduct(item)}>
                    <Subtitle color={'mt-2'} value={item.titletranslation}></Subtitle>
                </button>
                <StructurePrice main={main} item={item} setPrice={setPrice} price={price}/>
                <button onClick={()=>addToCart(item,1,price)} className={'btn rounded-0 py-3 mt-4 btn-block bordercolor1 color1'}>{main.traducciones.agregar_al_carrito}</button>
                <a
                    className={'link'}
                    href={item.menulink.linkTranslation+'/'+item.cataliastranslation+'/'+item.aliastranslation+'-'+item.id}
                    //onClick={()=>setProduct(item)}
                    className={'btn rounded-0 py-3 mt-4 btn-block bordercolor2 color2'}>
                    {main.traducciones.mas_detalles}
                </a>
            </div>
        </Col>
    </Row>
}

export function toURI(value) {
    let str = value.replace(/\s+/g, '-').toLowerCase()
    str = str.replace('.', '').toLowerCase()
    return str
}

export function Cartcontent(props) {
    let {cartContent, domains, main, removeItem, toggleCart,qtyItems}=props
    let checkout=false
    if(props.checkout !== undefined){
        checkout=true
    }
    let symbol='$'
    const [total, setTotal]=useState(0)
    const [subtotal, setSubTotal]=useState(0)
    const [discounted, setDiscounted]=useState(0)
    const [tax, setTax]=useState(0)
    let countprice=0;
    let countdiscount = 0
    if(cartContent !== 'loading' && cartContent !== undefined && cartContent !== null && cartContent.length > 0) {
        let cat=cartContent.map(item=>{
            let discount=0
            let finalprice=item.item.price

            if(main !== undefined && main.client !== null && main.client.active_membership !== null && main.client.active_membership.membership.product_discount > 0){
                discount=(main.client.active_membership.membership.product_discount*finalprice)/100
                finalprice=finalprice-discount
                countdiscount=countdiscount+discount
            }

            countprice=countprice + (parseFloat(finalprice) * item.qty)
            return null;
        })

        Promise.all(cat).then(()=>{
            let subtotalval=countprice
            let taxval=(countprice*main.result.tax)/100
            let totalval=subtotalval+taxval
            setSubTotal(subtotalval)
            setTax(taxval)
            setTotal(totalval)
            setDiscounted(countdiscount)
        })


        return <ul className="list-group rounded-0 p-0 border-0 position-sticky top">
            {
                cartContent.map((item, index) => {
                    let price = item.item.price
                    let img
                    let name = item.type == 2 ? item.item.titletranslated : item.item.titletranslation
                    if (item.item.img !== undefined && item.item.img !== null) {
                        img = item.item.img
                    } else {
                        img = 'img/noimg.jpg'
                    }

                    let discount=0
                    if(main !== undefined && main.client !== null && main.client.active_membership !== null && main.client.active_membership.membership.product_discount > 0){
                        discount=main.client.active_membership.membership.product_discount
                    }

                    return <li className={'list-group-item border-0 justify-content-between align-items-center'}
                        key={'caritem' + index}>
                        <div className={'row'}>
                            <div className={'col-4 col-lg-3 px-0'}>
                                <div className={'w-100 squareimage'}
                                     style={{width:60, height:85, backgroundImage: 'url(' + domains.domain + '/' + img + ')'}}></div>
                            </div>
                            <div className={'col'}>
                                <button onClick={() => removeItem(item.item.id,index)}
                                        style={{fontSize: 10}} className={'bgcolor1 text-white border-0 mt-1 float-right'}>
                                    <Icons.X className={'float-right'} size={15}/></button>
                                <p className={'m-0 color1'}>{name}</p>
                                <div><Moneyformat discount={discount} symbol={symbol} value={price}/></div>
                                <div>{main.traducciones.qty}: {item.qty}</div>

                            </div>
                        </div>
                    </li>
            }
            )}
            <li className={'list-group-item border-0 d-flex justify-content-between align-items-center'}
                key={'totalitemprice'}>
                <div className={'w-100'}>
                    <div className={'row border-bottom pb-2 mb-3'}>
                        <div className={'col'}>
                            <small>{main.traducciones.items_on_cart}</small>
                        </div>
                        <div className={'col text-right'}>
                            <small>{qtyItems}</small>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col'}>
                            <small>Subtotal</small>
                        </div>
                        <div className={'col text-right'}>
                            <small><Moneyformat value={subtotal} symbol={symbol}/></small>
                        </div>
                    </div>

                    {discounted > 0 ?
                        <div className={'row'}>
                            <div className={'col'}>
                                <small>{main.traducciones.discount}</small>
                            </div>
                            <div className={'col text-right'}>
                                <small><Moneyformat value={discounted} symbol={symbol}/></small>
                            </div>
                        </div>
                    : null}

                    <div className={'row'}>
                        <div className={'col'}>
                            <small>Tax</small>
                        </div>
                        <div className={'col text-right'}>
                            <small><Moneyformat value={tax} symbol={symbol}/></small>
                        </div>
                    </div>

                    <div className={'row color2 mt-2'}>
                        <div className={'col'}>
                            Total
                        </div>
                        <div className={'col text-right'}>
                            <Moneyformat value={total} symbol={symbol}/>
                        </div>
                    </div>

                </div>
            </li>
            {
                !checkout ?
                    <li className={'list-group-item border-0 w-100 d-flex justify-content-between align-items-center'} key={'procedd'}>
                        <a href={'/checkout'} className={'bgcolor2 p-3 text-white text-uppercase'}>{main.traducciones.abrir_orden}</a>
                        <button className={'link'} onClick={()=>toggleCart()}><FontAwesomeIcon icon={faChevronLeft} /> {main.traducciones.volver}</button>
                    </li>
                :null
            }

        </ul>
    }else if(cartContent === 'loading'){
        return <Loader />
    }else{
        return <>
                <div className={'alert alert-info border position-sticky top'}>
                    {main.traducciones.carrito_vacio}
                </div>
                <button className={'link'} onClick={()=>toggleCart()}><FontAwesomeIcon icon={faChevronLeft} /> {main.traducciones.volver}</button>
            </>
    }
}

export const Testimonialitem = props => {

    let {item} = props
    return <div className={'color1'}><Subtitle value={item.name}></Subtitle>
    <div className={'my-3'}>
        <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> {item.value}/5 • {item.date}
    </div>
    <p>{item.message}</p></div>
}

export function Card(props) {
    return (
        <div className={'card rounded '+props.classStyle} {...props}>
            {props.title !== undefined && props.title !== null ?
                <header className={'panel_header bg-white border-top-0 border-right-0 border-left-0 border-'+props.variant}>
                    {props.subtitle !== undefined && props.subtitle !== null ?
                        <div className={'float-right d-inline-block pt-3'}>{props.subtitle}</div>
                        :null}
                    <h2 className={'title pull-left text-'+props.variant}>{props.title}</h2>
                </header> : null}
            <div className={'card-body'}>
                {props.children}
            </div>
        </div>
    )
}

export function Loadersm() {
    return  <FontAwesomeIcon icon={faSpinner} spin size={"lg"} />
}


export function Loader() {
    return <div className={'py-5 text-center'}>
        <div className="portal-loader-logo-wrapper">
            <div className="portal-loader-logo">
                <div className="portal-loader-logo__circle portal-loader-logo__circle__bottom"></div>
                <div className="portal-loader-logo__circle portal-loader-logo__circle__top"></div>
                <div className="portal-loader-logo__circle portal-loader-logo__circle__left"></div>
                <div className="portal-loader-logo__circle portal-loader-logo__circle__right"></div>
                <div className="portal-loader-logo__circle portal-loader-logo__circle__inner"></div>
            </div>
            <div className="portal-loader-logo-text">
                <FontAwesomeIcon icon={faSpinner} spin size={"lg"} />
            </div>
        </div>
    </div>
}

export const removeHTML = (val) => val.replace(/(<([^>]+)>)/gi, "")

export function Title(props) {
    return (<div {...props}><h4 className={'title text-uppercase font-weight-light '+props.color}>{removeHTML(props.value)}</h4></div>)
}

export function Subtitle(props) {
    return (<h5 className={'title font-weight-light mb-0 '+props.color}>{removeHTML(props.value)}</h5>)
}

export function Subtitled(props) {
    return (<h5  {...props}>{removeHTML(props.value)}</h5>)
}

export const formatingNumber = (val) => {
    val=val.toString()
    val = val.replace(",","")
    val = val.replace(/[^\d.-]/g,"")
    if(val === "" || val === " "){
        val=0
    }
    val = parseFloat(val)
    return val % 1 == 0
        ?
        fraction.format(val)
        :
        formatter.format(val)
}

const fraction = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export function Numberformat(props) {
    return <span>{formatingNumber(props.value)}</span>
}


export function Moneyformat(props) {
    let { discount, value } = props
    let price = parseFloat(value)
    let finalprice = price
    if(discount !== undefined){
        discount=(finalprice*discount)/100
        finalprice = price - discount
    }

    return <span {...props}>
        {price !== finalprice
            ?
            <small className={'text-decoration-line-through opacity5 mr-2'}>
                <small>US$</small>
                <Numberformat value={price}/>
            </small>
            :
            null
        }
        <small>US$</small>
        <Numberformat value={finalprice}/>
    </span>
}

