import React, {useState} from "react";
import {Cartcontent, Loader, Moneyformat} from './General'
import * as Bootstrap from "react-bootstrap"
import Select from "react-select";

function Checkout(props) {
    let {main, domains, cartContent}=props

    let sessionuser= main.client

    if(sessionuser === undefined || sessionuser === null){
        window.location.href='/login?back=checkout';
    }

    const [totalval,setTotalval]=useState(0);
    const [totalcart,setTotalcart]=useState(0);
    const [discounted,setDiscounted]=useState(0);
    const [totalTax,setTotalTax]=useState(0);
    const [selectedEventKey,setSelectedEventKey]=useState("0");
    const [payment,setPayment]=useState(1);
    const [shipping,setShipping]=useState(1);
    const [shippingPrice,setShippingPrice]=useState(0);
    const [loading,setLoading]=useState(false);

    let locationobject = {}
    let countryobject = {}

    if(sessionuser){
        locationobject['value'] = sessionuser.state.id;
        locationobject['label'] = sessionuser.state.name;
        countryobject['value'] = sessionuser.countryid;
        countryobject['label'] = sessionuser.country.name;
    }

    const [locationid,setLocationid]=useState(locationobject);
    const [countryid,setCountryid]=useState(countryobject);
    const [content,setContent]=useState(null);


    let countries=[]
    let localities=[]
    main.countries.map(item=>countries.push({value:item.id,label:item.name}))
    main.localities.map(item=>localities.push({value:item.id,label:item.name}))

    let total=0
    let coundiscount=0

    const tax=parseFloat(main.result.tax)




    const selectKey = (e) => {
        let value=e.target.getAttribute('data-id')
        setSelectedEventKey(value)
    }

    const fetchCheckout = () => {
        let objectotosend={'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getCheckout/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd.result)
                }else{
                    //setTickets([])
                }
            })
            .catch((e) => console.log(e))
    }

    const settingShipping = (val) => {
        let value=val.target
        let price=parseFloat(val.target.getAttribute('data-price'))
        setShipping(value.value)
        setShippingPrice(price)
    }

    if(content == null){
        fetchCheckout()
        if(cartContent !== null && cartContent.length > 0){
            let cart=cartContent.map((item,index) => {
                let price = parseFloat(item.item.price)
                let discount=0
                if(main !== undefined && main.client !== null && main.client.active_membership !== null && main.client.active_membership.membership !== null && main.client.active_membership.membership.product_discount > 0){
                    discount=(main.client.active_membership.membership.product_discount*price)/100
                    price=price-discount
                    coundiscount=coundiscount+discount
                }

                total = total + price
                return null
            })

            Promise.all(cart).then(()=>{
                let calculatetax=(total*tax)/100
                setTotalcart(total)
                setTotalTax(calculatetax)
                setTotalval(total+calculatetax)
                setDiscounted(coundiscount)
            })
        }

    }

    const openOrder = () => {
        setLoading(true)
        let formData=new FormData();
        let address=document.getElementById('address').value
        let address2=document.getElementById('address2').value
        let zipcode=document.getElementById('zipcode').value
        let phone=document.getElementById('phone').value
        formData.append('payment',payment)
        formData.append('shipping',shippingPrice)
        formData.append('shippingid',shipping)
        formData.append('totalcart',totalcart)
        formData.append('total',totalval)
        formData.append('tax',totalTax)
        formData.append('discount',discounted)
        formData.append('cart',JSON.stringify(cartContent))
        formData.append('_csrfToken',domains.csfrtoken)
        formData.append('clientid',sessionuser.id)
        formData.append('address',address)
        formData.append('address2',address2)
        formData.append('zipcode',zipcode)
        formData.append('countryid',countryid.value)
        formData.append('stateid',locationid.value)
        formData.append('phone',phone)

        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        })

        let sentData={
            method:'POST',
            header: header,
            body:formData
        }
        let url =domains.domainapi+'openOrder/'+domains.apicode

        
        fetch(url,sentData).then((result)=>result.text())
            .then((json)=>{
                json=JSON.parse(json)
                if(json.error === '0'){
                    let url='/ordenes'
                    if(json.payment.online === true){
                        url = '/payment/'+json.payment.action+'/'+json.order.id
                    }
                    sessionStorage.removeItem('cart')
                    window.location.href = url;
                }else{
                    console.log(json.message)
                }
            })

    }

    return <div>{content !== null && cartContent !== 'loading' && cartContent !== null && cartContent !== undefined && cartContent.length > 0 ?
        <div className='home'>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <Bootstrap.Accordion className={'mb-5'}>
                            <Bootstrap.Card className={'rounded-0'}>
                                <Bootstrap.Card.Header>
                                    1. {content.translations.general_information}
                                </Bootstrap.Card.Header>
                                    <Bootstrap.Card.Body style={selectedEventKey === "0" ? {display:"block"} : {display:"none"}}>
                                        <div className={'row'}>
                                            <div className={'col'}>
                                                {sessionuser.name} {sessionuser.lastname} - {sessionuser.email}
                                                <p><small>{content.translations.not_you} <a className={'color2'} href={'logout'}>{content.translations.salir}.</a></small></p>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-12 col-md-6'}>
                                                <label>{content.translations.direccion}</label>
                                                <input className={'form-control'}
                                                       name={'address'}
                                                       id={'address'} required={true} defaultValue={sessionuser.address}/>
                                            </div>
                                            <div className={'col-12 col-md-6'}>
                                                <label>{content.translations.direccion2}</label>
                                                <input className={'form-control'}
                                                       name={'address2'}
                                                       id={'address2'} required={true} defaultValue={sessionuser.address2}/>
                                            </div>
                                            <div className={'col-12 col-md-6 mt-3'}>
                                                <label>Zipcode</label>
                                                <input className={'form-control'}
                                                       name={'zipcode'}
                                                       id={'zipcode'} required={true} defaultValue={sessionuser.zipcode}/>
                                            </div>
                                            <div className={'col-12 col-md-6 mt-3'}>
                                                <label>{content.translations.estado}</label>
                                                <Select onChange={(e)=>{
                                                    setLocationid(e)
                                                }} defaultValue={{value:sessionuser.state.id,label:sessionuser.state.name}}
                                                        isMulti={false} options={localities}/>
                                            </div>
                                            <div className={'col-12 col-md-6 mt-3'}>
                                                <label>{content.translations.pais}</label>
                                                <Select onChange={(e)=>setCountryid(e)}
                                                    defaultValue={{value:sessionuser.countryid,label:sessionuser.country.name}}
                                                        isMulti={false} options={countries}/>
                                            </div>
                                            <div className={'col-12 col-md-6 mt-3'}>
                                                <label>{content.translations.telefono}</label>
                                                <input id={'phone'} className={'form-control'} required={true} defaultValue={sessionuser.phone}/>
                                            </div>
                                        </div>
                                        <div className={'row mt-3'}>
                                            <div className={'col-12'}>
                                                <Bootstrap.Button data-id={'1'} onClick={(e)=>selectKey(e)}
                                                                  className={'bgcolor2 rounded-0 border-0 text-uppercase p-3 text-white'}
                                                                  variant="link">
                                                    {content.translations.siguiente}
                                                </Bootstrap.Button>
                                            </div>
                                        </div>
                                    </Bootstrap.Card.Body>
                            </Bootstrap.Card>

                            <Bootstrap.Card className={'rounded-0'}>
                                <Bootstrap.Card.Header>
                                    2. {content.translations.payment_method}
                                </Bootstrap.Card.Header>
                                <Bootstrap.Card.Body style={selectedEventKey === "1" ? {display:"block"} : {display:"none"}}>
                                    <p>{content.translations.choose_de_payment_method_you_preferred}.</p>
                                    {content !== null ? <div>{content.paymentmethods.map((item,index)=>{
                                            return <div className={'mb-3'} key={'kk-check-api-radio-payment'+index}>
                                                    <Bootstrap.Form.Check
                                                        type={'radio'}
                                                        name={'paymentmethod'}
                                                        id={'check-api-radio-payment'+index}
                                                        label={item.nametranslation}
                                                        value={item.id}
                                                        defaultChecked={content.paymentmethods[0].id}
                                                        onClick={(e)=>{
                                                                setPayment(e.target.value)
                                                        }}
                                                        />
                                                </div>
                                        }
                                    )}</div> : <Loader/>}
                                    <div className={'row mt-3'}>
                                        <div className={'col-6'}>
                                            <Bootstrap.Button data-id={'1'} onClick={(e)=>selectKey(e)}
                                                              className={'bgcolor1 rounded-0 border-0 text-uppercase p-3 text-white btn-block'}
                                                              variant="link">
                                                Anterior
                                            </Bootstrap.Button>
                                        </div>
                                        <div className={'col-6'}>
                                            {!loading ?
                                                <Bootstrap.Button data-id={'2'} onClick={(e)=>selectKey(e)}
                                                                  className={'bgcolor2 w-100 btn rounded-0 border-0 text-uppercase p-3 text-white'}
                                                                  variant="link">
                                                    {content.translations.siguiente}
                                                </Bootstrap.Button>
                                            : <Loader/> }
                                        </div>
                                    </div>
                                </Bootstrap.Card.Body>
                            </Bootstrap.Card>

                            <Bootstrap.Card className={'rounded-0'}>
                                <Bootstrap.Card.Header>
                                    3. {content.translations.shipping_method}
                                </Bootstrap.Card.Header>
                                <Bootstrap.Card.Body style={selectedEventKey === "2" ? {display:"block"} : {display:"none"}}>
                                    <p>{content.translations.choose_de_shipping_method_you_preferred}.</p>
                                    {content !== null ? <div>{content.shippingmethods.map((item,index)=>{
                                            return <div className={'mb-3'} key={'kk-check-api-radio-shipping'+index}>
                                                <Bootstrap.Form.Check type={'radio'} id={'check-api-radio-shipping'+index}>
                                                    <Bootstrap.Form.Check.Input type={'radio'} data-price={item.price} value={item.id} onChange={(e)=>{
                                                        settingShipping(e)
                                                    }} checked={parseFloat(shipping) === item.id ? true : false} />
                                                    <Bootstrap.Form.Check.Label>{item.nametranslation} {item.price > 0 ? <>- <Moneyformat symbol={'$'} value={item.price}></Moneyformat></> :null}
                                                    </Bootstrap.Form.Check.Label>
                                                </Bootstrap.Form.Check>
                                            </div>
                                        }
                                    )}</div> : <Loader/>}
                                    <div className={'row mt-3'}>
                                        <div className={'col-6'}>
                                            <Bootstrap.Button data-id={'1'} onClick={(e)=>selectKey(e)}
                                                              className={'bgcolor1 rounded-0 border-0 text-uppercase p-3 text-white btn-block'}
                                                              variant="link">
                                                Anterior
                                            </Bootstrap.Button>
                                        </div>
                                        <div className={'col'}>
                                            {!loading ?
                                                <Bootstrap.Button data-id={'1'} onClick={()=>openOrder()}
                                                                  className={'bgcolor2 rounded-0 border-0 text-uppercase p-3 text-white ml-2 btn-block'}
                                                                  variant="link">
                                                    Abrir orden
                                                </Bootstrap.Button>
                                                : <Loader/> }
                                        </div>
                                    </div>
                                </Bootstrap.Card.Body>
                            </Bootstrap.Card>
                        </Bootstrap.Accordion>
                    </div>
                    <div className={'col-12 col-md-4 col-lg-3'}>
                        <div className={'card card-body mb-5'}>
                            <p>Resumen</p>
                            <Cartcontent checkout={true} {...props}/>

                            <ul className="list-unstyled mb-0">
                                {!loading ? null : <Loader/> }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : <div className={'container py-5'}>
            {content !== null && (cartContent == null || cartContent.length === 0)
                ?
                    <div className={'alert alert-info'}>{main.traducciones.carrito_vacio}</div>
                : <Loader />
            }
        </div>}</div>
}

export default Checkout;
