import React, {useEffect, useState} from "react";
import {Loader, Title, Subtitle, MosaicProduct} from './General'
import {Container,Row,Col,Form,Alert,Pagination} from "react-bootstrap"
import {Helmet} from "react-helmet";

const Categories = (props) => {
    let {content, domains} = props
    return(
        <div className={'allservices'}>

            <Container className={'pb-5 color2'}>
                <Row>
                    {content.categories.map(ser=>{
                        return <Col key={'cat'+ser.id} className={'mb-5'} xs={12} lg={4} md={4}>
                                <a className={'link'}
                                   href={content.link.linkTranslation+'/'+ser.aliastranslation}>
                                    <div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+ser.img+')'}}></div>
                                    <Subtitle value={ser.titletranslation}></Subtitle>
                                </a>
                                <div className={'my-3 text-justify'}>{ser.descriptiontranslation}</div>
                                <a
                                    href={content.link.linkTranslation+'/'+ser.aliastranslation}
                                    className={'btn rounded-0 py-3 mt-4 btn-block bordercolor2 color2'}>{content.translations.seleccionar}</a>
                            </Col>
                        }
                    )}
                </Row>
            </Container>
        </div>
    )
}

function Shop(props) {
    let {domains, main} = props
    const [content,setContent]=useState(false)
    const [category,setCategory]=useState(null)

    const fetchHome = () => {
        let objectotosend={'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getProductsCategories/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd)
                }else{
                    //setTickets([])
                }
            })
    }

    useEffect(()=>fetchHome(),[])

    return <div className='home'>
            {content ?
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{content.content.titletranslations} - {main.result.name}</title>
                        <meta name="description" content={content.content.descriptiontranslations} />
                    </Helmet>
                    <div className={'panoimg mb-5'} style={{backgroundImage:'url('+domains.domain+'/'+content.content.img+')'}}>
                        <div className={'bgcolor1transparent w-100 h-100'}></div>
                    </div>
                    <Container>
                        <Row className={'pt-5 mb-0 color2'}>
                            <Col xs={12} md={8}>
                                <Title value={content.content.titletranslations}></Title>
                                <p className={'mb-5'}>{content.content.descriptiontranslations}</p>
                            </Col>
                        </Row>
                    </Container>
                    {category === null && <Categories domains={domains} content={content} setCategory={setCategory}/> }
                </div>
                :
                <div className={'py-5 text-center'}><Loader/></div>}
        </div>

}

export default Shop;
