import React, {useEffect, useState} from "react";
import {Container,Col, Row} from "react-bootstrap";
import {Loader, fetching, MosaicSuscription, Title} from "./General";
import {loadStripe} from '@stripe/stripe-js';
import {Elements,useStripe} from '@stripe/react-stripe-js';
import {Helmet} from "react-helmet";


const CheckoutForm = (props) => {
    let {membership, domains,sessionuser, clearMembership} = props
    const stripe = useStripe()

    const fetchCheckout = () =>{
        let formData = new FormData()
        formData.append('membership', JSON.stringify(membership));
        formData.append('client', JSON.stringify(sessionuser));
        formData.append('_csrfToken',domains.csfrtoken)
        let url=domains.domainapi+'getCheckoutSessionStripe/'+domains.apicode

        fetch(url, {
            method: 'POST',
            body:formData
        })
            .then(function(response) {
                return response.json();
            })
            .then(function (session) {
                if(session !== null){
                    clearMembership()
                    return stripe.redirectToCheckout({ sessionId: session.id });
                }
            })
            .then(function(result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.

            });
    }

    if(stripe !== null){
        fetchCheckout()
    }

    return <div>
        {stripe === null ?
            <Loader/>
            :
            <div>Redirecting</div>
        }
    </div>
}

const Stripe = (props) => {
    let {domains,membership,payment} = props
    const [clientSecret,setClientSecret] = useState(null)
    let paymentkey
    if(!payment.test){
        paymentkey=payment.public
    }else{
        paymentkey=payment.public_test
    }
    let publick=paymentkey


    const stripePromise = loadStripe(publick);

    const fetchClientSecret = () =>{
        let objectotosend={amount:membership.price}
        let url = domains.domainapi+'getClientSecretStripe/'+domains.apicode
        fetching(url,objectotosend,setClientSecret)
    }

    useEffect(()=>fetchClientSecret(),[])
    let options

    if(clientSecret !== null){
        options = {
            // passing the client secret obtained from the server
            clientSecret: clientSecret.client_secret,
        }
    }
    return <div>
        {clientSecret === null ?
            <Loader/>
            :
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm {...props}/>
            </Elements>
        }
    </div>
}

function Memberships(props) {
    let {domains, main,sessionuser} = props
    let sessionmembership=sessionStorage.getItem('membership')

    if(sessionmembership !== null) {
        sessionmembership = JSON.parse(sessionmembership)
    }else{
        sessionmembership = null
    }

    const [memberships,setMemberships] = useState(null)
    const [membership,setMembership] = useState(sessionmembership)

    const clearMembership = () => {
        sessionStorage.removeItem('membership')
        setMembership(null)
    }

    const fetchMemberships = () => {
        let objectotosend={'langid':domains.language,'user':sessionuser}
        let url = domains.domainapi+'getMemberships/'+domains.apicode
        fetching(url,objectotosend,setMemberships)
    }

    useEffect(()=>fetchMemberships(),[])

    if(props.sessionuser === null && membership !== null){
        window.location.href='/login?back=memberships'
    }

    return <>
            {memberships === null
                ?
                    <Loader/>
                :
                <>
                    <div className={'panoimg mb-5'} style={{backgroundImage:'url('+domains.domain+'/'+memberships.content.img+')'}}>
                        <div className={'bgcolor1transparent w-100 h-100'}></div>
                    </div>
                    <Container className={'py-5'}>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{memberships.content.titletranslations} - {main.result.name}</title>
                            <meta name="description" content={memberships.content.descriptiontranslations} />
                        </Helmet>

                        <Container>
                            <Row className={'pt-5 mb-0 color2'}>
                                <Col xs={12} md={8}>
                                    <Title value={memberships.content.titletranslations}></Title>
                                    <p className={'mb-5'}>{memberships.content.descriptiontranslations}</p>
                                </Col>
                            </Row>
                        </Container>
                        {membership === null ?
                            <Row className={'pt-5 justify-content-center'}>
                                {memberships.memberships.map(item => {
                                        return <Col key={'suscrip' + item.id} className={'mb-5'} xs={12} lg={4} md={3}>
                                            <MosaicSuscription
                                                currentmemberships={memberships.current_membership}
                                                main={main}
                                                domains={domains}
                                                item={item}
                                                setMembership={setMembership} />
                                        </Col>
                                    }
                                )}
                                
                            </Row>
                            :
                            <div className={'card card-body'}>
                                <div className={'mb-5'}>
                                    <button className={'link'} onClick={()=>clearMembership()}>{main.traducciones.volver}</button>
                                </div>
                                <Stripe clearMembership={clearMembership} membership={membership} payment={memberships.payment} {...props}/>
                            </div>
                        }
                    </Container>
                    </>
            }
        </>

}

export default Memberships;
