import React, {useState,useEffect} from 'react';
import {Sideprofile,Loader,Title} from './General'
import * as Bootstrap from "react-bootstrap";
function Inbox(props){
    let sessionuser=props.sessionuser
    let domains=props.domains
    let main=props.main
    const [details,setDetails] = useState(null)
    const [loadedMessages, setMessages] = useState(false)
    const [doneload, setLoad] = useState(0)
    const [showPopup, setShowPopUp] = useState(false)
    const handelSetMessages = (value) => setMessages(value)
    const handleSetLoad = () => setLoad(1)

    const handleSetShowPopUp = (e, details) => {
        setDetails(details.message)
        setShowPopUp(true)
        let paramsd={messageid:details.message.id}
        paramsd=btoa(JSON.stringify(paramsd))
        let urld=props.domainapi+'setmessagesread/'+props.apicode+'/'+paramsd
        fetch(urld).then((response)=>{
            return response.text()}
        ).then((json)=>{
            //console.log(json)
        })

    }
    const handleSetHidePopUp = () => {
        setShowPopUp(false)
        setDetails(null)
        setLoad(0)
    }

    let params={userid:sessionuser.id}
    params=btoa(JSON.stringify(params))
    let url=domains.domainapi+'getmessages/'+domains.apicode+'/'+params
    useEffect(() => {
        if(doneload === 0){
            fetch(url).then((response)=>{
                return response.json()}
            ).then((json)=>{
                handelSetMessages(json)
                handleSetLoad(1)
            })
        }
    })


        return(
            <div className={'messages'}>
                <Bootstrap.Container fluid={true}>
                    <Bootstrap.Row>
                        <Bootstrap.Col>
                            <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                        </Bootstrap.Col>
                        <Bootstrap.Col xs={12} sm={12} md={9} lg={10}>
                            <div id={'mailBodyList'} className={'col-12 col-md-8 col-lg-10'}>
                                <div className={'mail-body-content'}>
                                    <Title className={'mb-5'} value={main.traducciones.mensajes} />
                                    {loadedMessages ?
                                        <ul className={'mail-list list-group ps ps--active-y'}>
                                            {loadedMessages.countresult > 0 ?
                                                <div>
                                                    {loadedMessages.messages.map((item)=>{
                                                        return(<li onClick={(e)=>handleSetShowPopUp(e,item)} key={'itemmsg'+item.message.id}
                                                                   className={item.message.readed ? 'mail-item list-group-item list-group-item-action ' : 'mail-item unread list-group-item list-group-item-action'}>
                                                                                                            <div className="mail-item-body">
                                                                <div className={'float-right'}>
                                                                    <span className={'badge badge-info'}>{item.message.date}</span>
                                                                </div>
                                                                <h6>{item.message.readed ? null : <span className={'badge badge-danger text-white mr-1'}>Nuevo</span>} {item.message.subject}</h6>
                                                                <p>{item.message.shortbody}</p>
                                                            </div>
                                                        </li>)
                                                    }
                                                    )}
                                                </div>
                                                : <div className={'p-3'}><div className={'alert alert-info'}>No hay mensajes.</div></div>}
                                        </ul>
                                    :
                                    <Loader />}
                                </div>
                            </div>
                        </Bootstrap.Col>
                    </Bootstrap.Row>
                </Bootstrap.Container>
                <Bootstrap.Modal show={showPopup} onHide={handleSetHidePopUp}>
                    {details !== null ?
                        <div>
                            <Bootstrap.Modal.Header closeButton>
                                <Bootstrap.Modal.Title>{details.subject}</Bootstrap.Modal.Title>
                            </Bootstrap.Modal.Header>
                            <Bootstrap.Modal.Body><div dangerouslySetInnerHTML={{__html:details.body}}></div></Bootstrap.Modal.Body>
                        </div> : <Loader />}

                </Bootstrap.Modal>
            </div>
        )
}

export default Inbox;
