import React, {useState, useEffect} from 'react';
import {Sideprofile, Title, Subtitle, cancelOrder, Loader} from './General'
import 'moment-timezone';
import Moment from 'react-moment';
import * as Bootstrap from "react-bootstrap"

function Ordenes (props){
    let sessionuser=props.sessionuser
    let domains=props.domains
    let main=props.main
    const [ordenes,setOrdenes]=useState(null)
    const [page, setPage] = useState(1);

    const loadContent = () => {
        if(sessionuser !== null){
            let params = {userid:sessionuser.id, langid:domains.language, page:page}
            params=btoa(JSON.stringify(params))
            let url=domains.domainapi+'getordenes/'+domains.apicode+'/'+params
            fetch(url).then(function (response) {
                return response.text()
            }).then((jsonvalue)=>{
                console.log(jsonvalue);
                jsonvalue = JSON.parse(jsonvalue)
                setOrdenes(jsonvalue)
            })
        }else{
            window.location.href = '/login';
        }
    }
    const handlePage = (value) => {
        setPage(value)
        setOrdenes(null)
    }

    let items = []
    if(ordenes === null ){
        loadContent()
    }else{
        if(ordenes.pages > 0){
            for (let number = 1; number <= ordenes.pages; number++) {
                items.push(
                    <Bootstrap.Pagination.Item value={number} key={'pagination'+number} onClick={()=>{handlePage(number)}} active={number === page}>
                        {number}
                    </Bootstrap.Pagination.Item>
                )
            }
        }
    }

    return <div className={'ordenes'}>
        <Bootstrap.Container>
            <Bootstrap.Row>
                <Bootstrap.Col>
                    <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                </Bootstrap.Col>
                <Bootstrap.Col xs={12} sm={12} md={9} lg={10}>
                    <Title className={'mb-5'} value={main.traducciones.ordenes} />
                        <div className={'mb-5 pb-5'}>
                            {ordenes !== null ?
                                <>
                                {ordenes.orders.length > 0 ?
                                    <div>
                                        {ordenes.orders.map((item)=>{
                                        return <div key={'order'+item.id} className={'mb-4 card card-body border'}>
                                            <Subtitle value={'Detalles órden #'+item.id} />
                                            <p className={'mb-0 mt-3'}>Estado de orden: <span className={'badge badge-'+item.orders_type.classname}>{item.translateordertype}</span></p>
                                            <p className={'mb-0'}>Fecha de apertura: <span className={'badge badge-light border'}><Moment utc tz={'America/Santo_Domingo'} format="D MMM YYYY h:mm a">{item.date}</Moment></span>
                                            </p>
                                            <p className={'mb-0'}>Forma de pago: <span className={'badge badge-secondary'}>{item.translatepaymentmethod}</span></p>
                                            <div className={'row mt-3'}>
                                                <div className={'col'}>
                                                    <Bootstrap.Button
                                                        href={'/ordendetail/'+item.id}
                                                        //onClick={()=>handleShow(item)}
                                                        className={'btn btn-block'}>
                                                        Ver detalle de orden
                                                    </Bootstrap.Button>
                                                </div>
                                                {item.statusid === 1 && item.payment_method.online === 1 ? <div className={'col'}>
                                                    <Bootstrap.Button href={'/payment/'+item.payment_method.action+'/'+item.id} className={'btn btn-success btn-block'}>Hacer pago</Bootstrap.Button>
                                                </div>:null}
                                                {item.statusid === 1 ? <div className={'col'}>
                                                    <Bootstrap.Button onClick={()=>cancelOrder(item.id, domains)} className={'btn btn-secondary btn-block text-white'} >Cancelar</Bootstrap.Button>
                                                </div>:null}

                                            </div>
                                        </div>
                                        })}
                                        <div className={'paginationspace mt-3'}>
                                            <Bootstrap.Pagination>{items}</Bootstrap.Pagination>
                                        </div>
                                    </div>
                                : <div className={'alert alert-info'}>Esta cuenta no tiene órdenes.</div>}
                                </>
                            :
                                <Loader/>
                            }
                        </div>
                </Bootstrap.Col>
            </Bootstrap.Row>
        </Bootstrap.Container>
    </div>



}
export default Ordenes;
