import React, {useState} from "react";
import {Loader,Title} from './General'
import * as Bootstrap from "react-bootstrap"
import {Helmet} from "react-helmet";

function About(props) {
    let {domains,main} = props
    const [content,setContent]=useState(false)
    const fetchAbout = () => {
        let objectotosend={'langid':domains.language}
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getAbout/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd.result)
                }else{
                    //setTickets([])
                }
            })
    }

    const [contentmodal, setcontentmodal] = useState(false);
    const [lgShow, setLgShow] = useState(false);

    const assigntoModal = (e) =>{
        let get=e.target.getAttribute('data-link')
        let video=e.target.getAttribute('data-video')
        let type=e.target.getAttribute('data-type')
        setLgShow(true)
        if(type === "2"){
            setcontentmodal(<video autoPlay loop muted width={'100%'}>
                <source src={video} type="video/mp4" />
            </video>)
        }else{
            setcontentmodal(<img className={'w-100'} src={get}/>)
        }

    }
    if(!content){
        fetchAbout()
    }

    return <div className='home'>
            {content ? <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{content.about.titletranslation} - {main.result.name}</title>
                    <meta name="description" content={content.about.descriptiontranslation} />
                </Helmet>

                <Bootstrap.Modal
                    size="md"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Bootstrap.Modal.Header closeButton>
                    </Bootstrap.Modal.Header>
                    <Bootstrap.Modal.Body className={'m-0'}>
                        {contentmodal}
                    </Bootstrap.Modal.Body>
                </Bootstrap.Modal>
                <div className={'panoimg'} style={{backgroundImage:'url('+domains.domain+'/'+content.aboutmain.img+')'}}>
                    <div className={'bgcolor1transparent w-100 h-100'}></div>
                </div>
                <Bootstrap.Container>
                    <Bootstrap.Row className={'py-5 my-5 align-items-center '}>
                        <Bootstrap.Col xs={12} md={6}>
                            <Title color={'color2'} value={content.about.titletranslation}/>
                            <div className={'color2 mt-5 text-justify'}>
                                {content.about.descriptiontranslation}
                            </div>
                        </Bootstrap.Col>
                        <Bootstrap.Col xs={12} md={6}>
                            <div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+content.about.img+')'}}></div>
                        </Bootstrap.Col>
                    </Bootstrap.Row>
                </Bootstrap.Container>
                <Bootstrap.Container fluid className={'bgcolor2 color3 text-center instalaciones'}>
                    <Bootstrap.Row className={'py-5 my-5 justify-content-center'}>
                        <Bootstrap.Col >
                            <Bootstrap.Container className={'py-5'}>
                                <Title value={'Instalaciones'} color={'color3'}/>
                                    <div className={'galeria mt-5 row justify-content-center'}>
                                        {content.galeria.map(gal=>
                                            <Bootstrap.Col xs={6} md={3}>
                                                <a onClick={(e)=>assigntoModal(e)}>
                                                    <div data-type={gal.type}
                                                        data-video={domains.domain+'/'+gal.video}
                                                        data-link={domains.domain+'/'+gal.img} className={'squareimaged square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+gal.img+')'}}></div>
                                                </a>
                                            </Bootstrap.Col>
                                        )}
                                    </div>
                            </Bootstrap.Container>
                        </Bootstrap.Col>
                    </Bootstrap.Row>
                </Bootstrap.Container>
                <Bootstrap.Container>
                    <Bootstrap.Row className={'py-5 my-5 align-items-center'}>
                        <Bootstrap.Col xs={12} md={6}>
                            <div className={'w-100 squareimaged square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+content.specialist.img+')'}}></div>
                        </Bootstrap.Col>
                        <Bootstrap.Col >
                            <Title color={'color2'} value={content.specialist.titletranslation}/>
                            <div className={'color2 mt-5 text-justify'}>
                                {content.specialist.descriptiontranslation}
                            </div>
                        </Bootstrap.Col>
                    </Bootstrap.Row>
                </Bootstrap.Container>
            </div> : <div className={'py-5 text-center'}><Loader/></div>}
        </div>
}

export default About;
