import React from 'react';
import {Sideprofile,Subtitle,Loader,Moneyformat} from './General'


class Logout extends React.Component{


    sendLogout(){
        sessionStorage.removeItem('user')
        window.location.href = '/?message=Sesión cerrada exitosamente.&typemsg=success';
    }

    render(){
        this.sendLogout()
        return (
          <div className={'container py-5'}>
              <Loader />
          </div>
      )
    }
}

export default Logout;
