import React, {useState} from 'react';
import {Loader, Subtitle} from './General'

function Login(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const backlink = params.get('back');
    let main=props.main
    let domains=props.domains
    const [messagealert,setMessagealert]=useState("")
    const [isEmpty,setIsEmpty]=useState(false)
    const [isEmptyPass,setIsEmptyPass]=useState(false)
    const [loading,setLoading]=useState(false)
    const encodeToPost = (obj) => {
        let value=Object.keys(obj).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&')
        return value
    }

    const encodeUnicode = (str) => {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    }


    const sendLogin = (values) => {
        setLoading(true)
        values=btoa(JSON.stringify(values))
        let url =domains.domainapi+'sendLogIn/'+domains.apicode+'/'+values
        fetch(url).then(response=>response.text()
        ).then((json)=>{
            json=JSON.parse(json)
            console.log(json)
            if(json.result.state === "success"){
                let result=JSON.stringify(json.result.details)
                sessionStorage.setItem('user', result)
                let urltoredirect = json.traducciones.profilelink
                if(backlink !== null){
                    urltoredirect='/'+backlink
                }
                window.location.href = urltoredirect;
            }else{
                setLoading(false)
                setMessagealert(json.result.message)
            }

        })
    }

    const validateLogin = () => {
        let missing = 0
        let emaildfield=document.getElementById('emaildfield').value
        let passwordfield=document.getElementById('passwordfield').value
        if(emaildfield === ""){
            setIsEmpty(true)
            missing++
        }else{
            setIsEmpty(false)
        }
        if(passwordfield === ""){
            setIsEmptyPass(true)
            missing++
        }else{
            setIsEmpty(false)
        }

        if(missing === 0){
            let values= {
                loading:true,
                langid:domains.language,
                email:emaildfield,
                pass:passwordfield,
            }
            sendLogin(values)
        }

    }

    let newaccountlink='/nueva-cuenta'
    if(newaccountlink !== null){
        newaccountlink=newaccountlink+'?back='+backlink
    }

    return <div className="signin-panel p-0 bgimg" style={{backgroundSize:"100% auto",backgroundPosition:'center', backgroundImage:"url("+domains.domain+'/'+main.result.img+")"}}>
          <div className="signin-sidebar py-4">
              <div className={'row justify-content-center'}>
                  <div className={'col-12 col-md-6 p-5'}>
                      <div className="signin-sidebar-body card py-5 px-3 card-body">
                          {messagealert !== "" ? <div className={'alert alert-warning py-1 mt-2 validatespaceemail'}>{messagealert}</div> : null}
                          <div className="signin-form" id={'signIn'}>
                              <Subtitle value={main.traducciones.log_in} color={' mb-3'}/>
                              <div className="form-group">
                                  <label>Email</label>
                                  <input id={'emaildfield'} name={"email"} type="email" className="form-control form-control-lg"
                                  />
                                  {isEmpty ? <div className={'validatespace alert alert-warning py-1 mt-2 validatespaceemail'}>{main.traducciones.este_campo_no_puede_estar_vacio}</div> : null}
                              </div>

                              <div className="form-group">
                                  <label className="d-flex justify-content-between">
                                      <span>{main.traducciones.contrasena}</span>
                                  </label>
                                  <input name={'password'} id={'passwordfield'} type="password" className="form-control form-control-lg"
                                  />
                                  {isEmptyPass ? <div className={'validatespace alert alert-warning validatespaceemail py-1 mt-2'}>{main.traducciones.este_campo_no_puede_estar_vacio}</div> : null}
                              </div>
                              <div className="form-group mt-5 d-flex mg-b-0">

                                  {loading ? <div><Loader/></div> : <div>
                                      <div></div>
                                      <button onClick={()=>{validateLogin()}} type={'button'} className="btn btn-brand-01 btn-lg bgcolor1 text-white btn-uppercase flex-fill mr-1">{main.traducciones.entrar}</button>
                                      <a href={newaccountlink} className="btn bgcolor2 text-white btn-uppercase btn-lg flex-fill mg-l-10">{main.traducciones.crear_cuenta}</a>
                                      <a href="/reset" className="tx-13 color2 btn mx-0 px-0">{main.traducciones.olvido_contrasena}</a>
                                  </div>}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
}

export default Login;
