import React, {useState} from "react";
import {Loader,Title,Subtitle,Moneyformat} from './General'
import {Alert} from "react-bootstrap"
import {Helmet} from "react-helmet"

const Detallecurso = (props) => {
    const id=props.match.params.id
    let {domains,addToCart,main} = props
    const [content,setContent]=useState(false)
    const [contentModal,setContentModal]=useState(null)
    const [lgShow, setLgShow] = useState(false);
    const fetchHome = () => {
        let objectotosend={'langid':domains.language,
            'itemid':id,
            session:domains.session
        }
        objectotosend=btoa(JSON.stringify(objectotosend))
        let url = domains.domainapi+'getCursodetail/'+domains.apicode+'/'+objectotosend
        fetch(url)
            .then(result=>result.json())
            .then(resultd=>{
                if(resultd.error === '0'){
                    setContent(resultd)
                }else{
                    //setTickets([])
                }
            })
    }
    const openModal = (item) => {
        setContentModal(item)
        setLgShow(true)
    }


    if(!content){
        fetchHome()
        return <Loader/>
    }else{
        let activecour=content.result.activecourses.map(item=>item.cursoid)
        return <div className='home'>
                {content ? <div className={'container'}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{content.result.content.titletranslation} - {main.result.name}</title>
                        <meta name="description" content={content.result.content.shortdescriptiontranslation} />
                    </Helmet>
                    <div className={'row justify-content-center'}>
                        <div className={'col col-md-8 col-lg-7'}>
                            <div className={'py-0 my-5 '}>
                                <div className={'panoimg mb-5'} style={{paddingBottom:'70%', backgroundImage:'url('+domains.domain+'/'+content.result.content.img+')'}}>
                                </div>
                                <div className={'text-center'}>
                                    <Title color={'color2'} value={content.result.content.titletranslation}/>
                                    <p className={'color2'}>{content.result.content.categorynametranslation}</p>
                                    {activecour.length > 0 && !activecour.includes(content.result.content.id) ?
                                        <>
                                            <Moneyformat className={'color2'} value={content.result.content.price} />
                                            <button onClick={()=>addToCart(content.result.content,2)} className={'btn rounded-0 py-3 mt-4 btn-block bordercolor1 color1'}>{main.traducciones.agregar_al_carrito}</button>
                                        </>
                                    : <Alert variant={'secondary'}>Ya tiene adquirido este curso</Alert> }
                                </div>
                                <div className={'row justify-content-center'}>
                                    <div className={'col-12 col-md-12 col-lg-12 mt-3 color2'}>
                                        <div className={'text-justify my-5'} dangerouslySetInnerHTML={{__html:content.result.content.shortdescriptiontranslation}}></div>
                                        <div className={'text-justify'} dangerouslySetInnerHTML={{__html:content.result.content.descriptiontranslation}}></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className={'py-5 text-center'}><Loader/></div>}
            </div>
    }


}

export default Detallecurso;
