import React, {useState} from 'react';
import 'moment-timezone';
import {Modal, Container, Row, Col, default as Bootstrap} from "react-bootstrap"
import {Subtitle, Loader, Title, Sideprofile} from "./General";

function Cursosactivos (props){
    let {domains,main}=props
    let sessionuser=domains.session
    const [cursos,setCursos]=useState(null)
    const [openModal,setOpenModal]=useState(false)
    const [courseOpenModal,setCourseOpenModal]=useState({})
    const handleClose = () => setOpenModal(false)

    const handleOpen = (name,video) => {
        setOpenModal(true)
        setCourseOpenModal({name:name,video:video})
    }
    const loadContent = () => {
            let params = {userid:domains.session.id, langid:domains.language}
            params=btoa(JSON.stringify(params))
            let url=domains.domainapi+'cursosActivos/'+domains.apicode+'/'+params
            fetch(url).then(function (response) {
                return response.json()
            }).then((jsonvalue)=>{
                setCursos(jsonvalue)
            })
    }

    useState(()=>loadContent(),['cursos'])

    return <div className={'course'}>
        <Modal size={'xl'} show={openModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{courseOpenModal.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html:"<iframe width=\"100%\" height=\"550\" src=\"https://www.youtube.com/embed/"+courseOpenModal.video+"?controls=0\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>"}}></div>
            </Modal.Body>
        </Modal>
            {cursos !== null ?
                <>
                    <div className={'panoimg mb-5'} style={{backgroundImage:'url('+domains.domain+'/'+cursos.content.img+')'}}>
                        <div className={'bgcolor1transparent w-100 h-100'}></div>
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                            </Col>
                             <Col xs={12} sm={12} md={12} lg={10}>
                                 <Row className={'mb-0 color2'}>
                                     <Col xs={12} md={12}>
                                         <Title value={cursos.content.titletranslations}></Title>
                                         <p>{cursos.content.descriptiontranslations}</p>
                                         <a href={cursos.content.aliastranslations} className={'btn mb-5 rounded-0 py-3 mt-4 btn-block bordercolor2 color2'}>Ver todos los cursos</a>
                                     </Col>
                                 </Row>
                                 <Row>
                                    {cursos.cursos.map(ser=>{
                                            return <Col key={'colomart'+ser.id} className={'mb-5'} xs={12} md={4}>
                                                <div className={'w-100 squareimage square mb-4'} style={{backgroundImage:'url('+domains.domain+'/'+ser.curso.img+')'}}></div>
                                                <Subtitle value={ser.cursonombre}></Subtitle>
                                                <p>{ser.cursocatnombre}</p>
                                                <div className={'my-3 text-justify'}>{ser.cursocatshortdesc}</div>
                                                <button onClick={()=>handleOpen(ser.cursonombre,ser.video)}
                                                        className={'btn rounded-0 py-3 mt-4 btn-block bordercolor2 color2'}>{main.traducciones.entrar}</button>
                                            </Col>
                                        }

                                    )}
                                 </Row>
                            </Col>
                        </Row>
                    </Container>
                </>
            : <Loader/>}
        </div>
}
export default Cursosactivos;
