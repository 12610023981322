import React, {useState} from 'react';
import * as Bootstrap from "react-bootstrap";
import {Loader, Sideprofile, Title} from './General'

function Edtarpperfil(props){
    let {domains,main}=props
    let sessionuser = main.client
    const [loading,setLoading]=useState(false)

    const validateFields = () => {
        setLoading(true)
        let fields =[]
        fields = document.getElementsByClassName('validate')
        fields = Array.from(fields)
        let objecttosend=new FormData()
        let empty=0
        let fieldaddress2=document.getElementById('address2').value
        objecttosend.append('address2',fieldaddress2)

        let checkfields=fields.map(fields => {
            let namefield=fields.getAttribute('name')
            let validationspace
            validationspace=document.getElementById('validatespace'+namefield)
            validationspace.innerHTML=''
            let value=fields.value
            if(value === ""){
                empty++
                validationspace.innerHTML='<div class="alert alert-warning py-1 mt-2">Este campo no puede estar vacío.</div>'
            }else{
                objecttosend.append(namefield,value)
                if(namefield === 'email' && value !== sessionuser.email){
                    let params={
                        email:value}
                        params=btoa(JSON.stringify(params))
                    let url =domains.domainapi+'validateEmail/'+domains.apicode+'/'+params

                    fetch(url,{
                        method:'GET',
                        credentials:'include'
                    }).then((result)=>result.json())
                    .then((json)=>{
                        if(json === true){
                            empty++
                            validationspace.innerHTML='<div class="alert alert-warning py-1 mt-2">Este correo ya está siendo utilizado.</div>'
                        }
                    })
                }
            }
            return true
        })
        Promise.all(checkfields).then(()=>{
            if(empty === 0){
                objecttosend.append('_csrfToken',domains.csfrtoken)
                objecttosend.append('userid',sessionuser.id)
                let header = new Headers({
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type': 'multipart/form-data'
                })
                let sentData={
                    method:'POST',
                    header: header,
                    body:objecttosend
                }

                let url =domains.domainapi+'sendUpdateProfile/'+domains.apicode
                fetch(url,sentData).then((result)=>result.text())
                    .then((json)=>{
                        json=JSON.parse(json)
                        if(json.error === 0){
                            let result=JSON.stringify(json.user)
                            sessionStorage.setItem('user', result)
                        }else{
                            console.log(json)
                        }
                        setLoading(false)
                    })
            }else{
                setLoading(false)
            }
        })

    }

    return <div className={'editarperfil'}>
            <Bootstrap.Container>
                <Bootstrap.Row>
                    <Bootstrap.Col>
                        <Sideprofile domains={domains} sessionuser={sessionuser} main={main}/>
                    </Bootstrap.Col>
                    <Bootstrap.Col xs={12} sm={12} md={9} lg={10}>
                        <div className="row">
                            <div className="col-sm-12">
                                <Title value={main.traducciones.modificando_perfil}/>
                                <label className={'mt-4'}>{main.traducciones.name}</label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input type="text" name={'name'} className="form-control validate" defaultValue={sessionuser.name}
                                    />
                                </Bootstrap.InputGroup>
                                <div id={'validatespacename'}></div>
                            </div>
                        </div>

                        <div className="row mg-b-5">
                            <div className="col">
                                <label>Email</label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input name={'email'} type="email" className="form-control validate" defaultValue={sessionuser.email} placeholder="Escriba su correo"
                                    />
                                </Bootstrap.InputGroup>
                                <div id={'validatespaceemail'}></div>
                            </div>
                            <div className={'col'}>
                                <label>{main.traducciones.phone}</label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input type="text" name={'phone'} className="form-control validate" id={'phone'}
                                           defaultValue={sessionuser.phone} />
                                </Bootstrap.InputGroup>
                                <div id={'validatespacephone'}></div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <label>{main.traducciones.direccion}</label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input type="text" name={'address'} className="form-control validate" id={'address'}
                                           defaultValue={sessionuser.address} />
                                </Bootstrap.InputGroup>
                                <div id={'validatespaceaddress'}></div>
                            </div>

                            <div className={'col'}>
                                <label>{main.traducciones.direccion} 2</label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input type="text" name={'address2'} className="form-control" id={'address2'}
                                           defaultValue={sessionuser.address2} />
                                </Bootstrap.InputGroup>
                                <div id={'validatespaceaddress2'}></div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <Bootstrap.Form.Label>{main.traducciones.ciudad}</Bootstrap.Form.Label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input type="text" name={'city'} className="form-control validate" id={'city'}
                                           defaultValue={sessionuser.city} />
                                </Bootstrap.InputGroup>
                                <div id={'validatespacecity'}></div>
                            </div>
                            <div className={'col'}>
                                <Bootstrap.Form.Label>{main.traducciones.estado}</Bootstrap.Form.Label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'} name={'stateid'} controlId="stateid">
                                    <Bootstrap.Form.Control className={'validate'} as="select" name={'stateid'} custom>
                                        {main.localities.map(item=><option key={'state'+item.id} selected={item.id === sessionuser.stateid ? true : false} value={item.id}>{item.name}</option>)}
                                    </Bootstrap.Form.Control>
                                </Bootstrap.InputGroup>
                                <div id={'validatespacestateid'}></div>
                            </div>


                            <div className={'col'}>
                                <Bootstrap.Form.Label>{main.traducciones.codigo_postal}</Bootstrap.Form.Label>
                                <Bootstrap.InputGroup size="lg" className={'mb-3'}>
                                    <input type="text" name={'zipcode'} className="form-control validate" id={'zipcode'}
                                           defaultValue={sessionuser.zipcode} />
                                </Bootstrap.InputGroup>
                                <div id={'validatespacezipcode'}></div>
                            </div>
                        </div>

                        <div className="form-group d-flex mb-5">
                            {!loading ? <button onClick={validateFields} className="btn btn-primary btn-uppercase btn-block btn-lg mt-3">{main.traducciones.guardar}</button> : <span className={'btn btn-primary btn-uppercase btn-block btn-lg mt-3'}><Loader/></span>}
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </Bootstrap.Container>
        </div>
}

export default Edtarpperfil;
