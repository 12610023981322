import React, {useState} from "react";
import * as Bootstrap from "react-bootstrap"
import * as Icons from "react-feather";
import GoogleMapReact from 'google-map-react';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import {Loadersm} from './General'


const AnyReactComponent = ({ text }) => <div style={{
    position: 'absolute',
    transform: 'translate(-50%, -100%)'
}}><MapMarkerIcon className={'color2'} size={65}/></div>;

function Contact(props) {
    let domains = props.domains
    let main = props.main.result
    let traducciones=props.main.traducciones

    let center = {
        lat: parseFloat(main.latitude),
        lng: parseFloat(main.longitude)
    }

    const [name,setName]=useState(null)
    const [message,setMessage]=useState(null)
    const [email,setEmail]=useState(null)
    const [phone,setPhone]=useState(null)
    const [warning,setWarning]=useState(false)
    const [loading,setLoadingSubmit]=useState(false)

    const validateEmail = (mail) =>{
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
        {
            return (true)
        }
        return (false)
    }

    const submitForm = () =>{
        if( name !== "" && name !== null &&
            phone !== "" && phone !== null &&
            message !== "" && message !== null &&
            email !== "" && email !== null){

            let validate=validateEmail(email)
            if(!validate){
                setWarning(<Bootstrap.Alert variant={'warning'} className={'mt-3'}>{traducciones.noesvalidoemail}</Bootstrap.Alert>)
            }else{

                setWarning(false)
                setLoadingSubmit(true)
                let formData = new FormData()
                formData.append('email',email)
                formData.append('phone',phone)
                formData.append('message',message)
                formData.append('name',name)
                formData.append("_csrfToken",domains.csfrtoken)
                let header = new Headers({
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type': 'multipart/form-data'
                });

                let sentData={
                    method:'POST',
                    mode: 'cors',
                    header: header,
                    body:formData
                };

                let url=domains.domainapi+'submitMessage/'+domains.apicode
                fetch(url,sentData).then(function (response) {
                    return response.text()
                }).then((jsonvalue)=> {
                    setWarning(<Bootstrap.Alert className={'mt-3'} variant={'success'}>El mensaje ha sido enviado. Muy pronto lo estaremos revisando.</Bootstrap.Alert>)
                    jsonvalue=JSON.parse(jsonvalue);
                    setLoadingSubmit(false)
                }).catch(()=>{
                    setWarning(<Bootstrap.Alert variant={'warning'} className={'mt-3'}>Error en el envio.</Bootstrap.Alert>)
                    setLoadingSubmit(false)
                })
            }

        }else{
            setWarning(<Bootstrap.Alert className={'mt-3'} variant={'warning'}>{traducciones.todosrequeridos}.</Bootstrap.Alert>)
        }
    }


    return (
        <div className='contacto'>
            <div className={'panoimg'} style={{backgroundImage:'url('+domains.domain+'/'+main.img+')'}}>
                <div className={'bgcolor1transparent w-100 h-100'}></div>
            </div>
            <div className={'container py-5'}>
                <Bootstrap.Row>
                    <Bootstrap.Col xs={12} md={6}>
                        <div className={'py-3'}>
                            {main.name !== "" ? <h5 className={'mt-0 mb-3 font-weight-light'}>{main.name}</h5> : null}
                            <p>
                            {main.facebook !== "" ? <a className={'color1 pr-3 d-inline-block'} href={main.facebook} rel="noreferrer" target={"_blank"}><Icons.Facebook/></a>:null}
                            {main.instagram !== "" ? <a className={'color1 pr-3 d-inline-block'} href={main.instagram} rel="noreferrer" target={"_blank"}><Icons.Instagram/></a>:null}
                            </p>
                        {main.phone !== "" ? <h5 className={'mt-3 mb-0 font-weight-light'}><a className={'color1'} href={'tel:'+main.phone}>{main.phone}</a></h5> : null}
                        {main.schedule !== "" ? <p className={'my-2'}>{main.scheduletranslated}</p> : null}
                        {main.address !== "" ? <p className={'my-0'}>{main.address}</p> : null}
                            <p className={'mt-5'}>{traducciones.paraservirles}</p>
                            <Bootstrap.Form.Group controlId="exampleForm.ControlInput1">
                                <Bootstrap.Form.Label>{traducciones.name}</Bootstrap.Form.Label>
                                <Bootstrap.Form.Control type="text" onChange={e=>setName(e.target.value)}  />
                            </Bootstrap.Form.Group>

                            <Bootstrap.Form.Group controlId="exampleForm.ControlInput1">
                                <Bootstrap.Form.Label>{traducciones.email}</Bootstrap.Form.Label>
                                <Bootstrap.Form.Control type="email" onChange={e=>setEmail(e.target.value)} />
                            </Bootstrap.Form.Group>

                            <Bootstrap.Form.Group controlId="exampleForm.ControlInput1">
                                <Bootstrap.Form.Label>{traducciones.phone}</Bootstrap.Form.Label>
                                <Bootstrap.Form.Control type="text" onChange={e=>setPhone(e.target.value)}/>
                            </Bootstrap.Form.Group>

                            <Bootstrap.Form.Group controlId="exampleForm.ControlTextarea1">
                                <Bootstrap.Form.Label>{traducciones.mensaje}</Bootstrap.Form.Label>
                                <Bootstrap.Form.Control as="textarea" rows={3} onChange={e=>setMessage(e.target.value)}/>
                            </Bootstrap.Form.Group>
                            <button
                                onClick={submitForm}
                                disabled={loading}
                                className={'btn btn-primary'}>
                                    { loading ? <Loadersm /> : traducciones.enviar }
                            </button>
                            {warning ? warning : null}
                        </div>
                    </Bootstrap.Col>
                    <Bootstrap.Col>
                        <div className={'mt-0'} style={{ height: '100%', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: main.googlemapspai }}
                                defaultCenter={center}
                                defaultZoom={15}
                            >
                                <AnyReactComponent
                                    lat={parseFloat(main.latitude)}
                                    lng={parseFloat(main.longitude)}
                                    text={main.name}
                                />
                            </GoogleMapReact>
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </div>
        </div>
    );
}

export default Contact;
